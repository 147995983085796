import * as React from 'react';
import 'react-dates/initialize';

import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import ContentContainer from './ContentContainer';
import StepWizardStepAbstract from '../../Common/StepWizard/StepWizardStepAbstract';

import {
  OFFER_MODAL_ADDRESS_TITLE,
  OFFER_MODAL_ADDRESS_CONTACT_PERSON_TITLE,
  OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL,
  OFFER_MODAL_ADDRESS_COMPANY_LABEL,
  OFFER_MODAL_ADDRESS_COMPANY_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_INVALID,
  OFFER_MODAL_ADDRESS_SALUTATION_LABEL,
  OFFER_MODAL_ADDRESS_SALUTATION_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_SALUTATION_INVALID,
  OFFER_MODAL_ADDRESS_PRENAME_LABEL,
  OFFER_MODAL_ADDRESS_PRENAME_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_PRENAME_INVALID,
  OFFER_MODAL_ADDRESS_LASTNAME_LABEL,
  OFFER_MODAL_ADDRESS_LASTNAME_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_LASTNAME_INVALID,
  OFFER_MODAL_ADDRESS_PHONE_LABEL,
  OFFER_MODAL_ADDRESS_PHONE_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_PHONE_INVALID,
  OFFER_MODAL_ADDRESS_EMAIL_LABEL,
  OFFER_MODAL_ADDRESS_EMAIL_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_EMAIL_INVALID,
  OFFER_MODAL_ADDRESS_COMPANY_INVALID,
} from '../../../constants/labels';
import { SALUTATION_ARRAY } from '../../../constants/constants';

import { ContactProps, ContactState } from '../../../@types/OrderModal.d';
import { Salutation, ClientLocation } from '../../../@types/Data.d';

/**
 *  Component to input contact info of the client
 */
export default class Contact extends StepWizardStepAbstract<
  ContactProps,
  ContactState
> {
  constructor(props: ContactProps) {
    super(props);

    this.state = { formValidated: false };

    this.onChangeBillingSubisdiary = this.onChangeBillingSubisdiary.bind(this);
    this.onChangeBillingType = this.onChangeBillingType.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePrename = this.onChangePrename.bind(this);
    this.onChangeSalutation = this.onChangeSalutation.bind(this);
  }

  /**
   * On change listener for the billing type switch
   *
   * @param event
   */
  onChangeBillingType(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeBillingType } = this.props;
    changeBillingType(
      event.currentTarget.checked ? 'PER_LOCATION' : 'LOCATION'
    );
  }

  /**
   * On change listener for the billing client location select
   *
   * @param pBillingClientLocation
   */
  onChangeBillingSubisdiary(pBillingClientLocation: any): void {
    const { changeBillingSubisdiary } = this.props;
    if (pBillingClientLocation && pBillingClientLocation !== null)
      changeBillingSubisdiary(pBillingClientLocation.data as ClientLocation);
  }

  /**
   * On change listener for the clients salutation select
   *
   * @param pSalutation
   */
  onChangeSalutation(pSalutation: any): void {
    const { changeSalutation } = this.props;
    if (pSalutation && pSalutation !== null)
      changeSalutation(pSalutation.value as Salutation);
  }

  /**
   * On change listener for the clients prename
   *
   * @param event
   */
  onChangePrename(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changePrename } = this.props;
    changePrename(event.currentTarget.value);
  }

  /**
   * On change listener for the clients lastname
   *
   * @param event
   */
  onChangeLastname(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeLastname } = this.props;
    changeLastname(event.currentTarget.value);
  }

  /**
   * On change listener for the clients phone number
   *
   * @param event
   */
  onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changePhone } = this.props;
    changePhone(event.currentTarget.value);
  }

  /**
   * On change listener for the clients email address
   *
   * @param event
   */
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeEmail } = this.props;
    changeEmail(event.currentTarget.value);
  }

  /**
   * On change listener for the clients company name
   *
   * @param event
   */
  onChangeCompany(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeCompany } = this.props;
    changeCompany(event.currentTarget.value);
  }

  render(): JSX.Element {
    const { formValidated } = this.state;
    const {
      user,
      client,
      clientLocations,
      billingClientLocation,
      billingType,
      company,
      salutation,
      prename,
      lastname,
      phone,
      email,
    } = this.props;

    return (
      <div>
        <ContentContainer title={OFFER_MODAL_ADDRESS_TITLE}>
          <Form
            ref={this.formRef}
            id="contact-form"
            validated={formValidated}
            noValidate
          >
            {client && billingType !== 'CLIENT' && (
              <>
                <Form.Row>
                  <Col xs={12} xl={6} className="switch-container">
                    <Form.Check
                      type="switch"
                      id="billing-clientLocation-switch"
                      label=""
                      checked={billingType === 'PER_LOCATION'}
                      onChange={this.onChangeBillingType}
                    />
                    <div className="sub-section-title">
                      {OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL(
                        client.transmissionType === 'OFFER'
                      )}
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row
                  className={`collapse-content height ${
                    billingType === 'LOCATION' ? 'shown' : 'hidden'
                  }`}
                >
                  <Col xs={12} xl={6}>
                    <Form.Group>
                      <Form.Label>
                        {OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL(
                          client.transmissionType === 'OFFER'
                        )}
                      </Form.Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        options={clientLocations.map((pClientLocation) => ({
                          value: pClientLocation.id,
                          label: pClientLocation.name,
                          data: pClientLocation,
                        }))}
                        value={
                          billingClientLocation
                            ? {
                                label: billingClientLocation?.name ?? '',
                                value: billingClientLocation?.id,
                                data: billingClientLocation,
                              }
                            : undefined
                        }
                        placeholder={OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_PLACEHOLDER(
                          client.transmissionType === 'OFFER'
                        )}
                        onChange={this.onChangeBillingSubisdiary}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        closeMenuOnScroll={false}
                      />
                      <Form.Control
                        value={
                          billingClientLocation &&
                          billingClientLocation !== null
                            ? billingClientLocation.id
                            : undefined
                        }
                        onChange={() => {}}
                        hidden
                        required={billingType === 'LOCATION'}
                      />
                      <Form.Control.Feedback type="invalid">
                        {OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <div className="separator" />
              </>
            )}
            <div className="sub-section-title">
              {OFFER_MODAL_ADDRESS_CONTACT_PERSON_TITLE}
            </div>
            <div className="separator" />
            {!user && (
              <Form.Row>
                <Col xs={12} xl={6}>
                  <Form.Group>
                    <Form.Label>{OFFER_MODAL_ADDRESS_COMPANY_LABEL}</Form.Label>
                    <Form.Control
                      as="input"
                      autoComplete="organization"
                      type="text"
                      value={company}
                      onChange={this.onChangeCompany}
                      placeholder={OFFER_MODAL_ADDRESS_COMPANY_PLACEHOLDER}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {OFFER_MODAL_ADDRESS_COMPANY_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
            <Form.Row>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>
                    {OFFER_MODAL_ADDRESS_SALUTATION_LABEL}{' '}
                  </Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    blurInputOnSelect
                    isSearchable={false}
                    options={SALUTATION_ARRAY}
                    value={SALUTATION_ARRAY.find(
                      (pSalutation) => pSalutation.value === salutation
                    )}
                    placeholder={OFFER_MODAL_ADDRESS_SALUTATION_PLACEHOLDER}
                    onChange={this.onChangeSalutation}
                  />
                  <Form.Control
                    value={
                      salutation && salutation !== null ? salutation : undefined
                    }
                    onChange={() => {}}
                    hidden
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_SALUTATION_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{OFFER_MODAL_ADDRESS_PRENAME_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="given-name"
                    type="text"
                    value={prename}
                    onChange={this.onChangePrename}
                    placeholder={OFFER_MODAL_ADDRESS_PRENAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_PRENAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{OFFER_MODAL_ADDRESS_LASTNAME_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="family-name"
                    type="text"
                    value={lastname}
                    onChange={this.onChangeLastname}
                    placeholder={OFFER_MODAL_ADDRESS_LASTNAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_LASTNAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{OFFER_MODAL_ADDRESS_EMAIL_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="email"
                    type="text"
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder={OFFER_MODAL_ADDRESS_EMAIL_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_EMAIL_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{OFFER_MODAL_ADDRESS_PHONE_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="tel"
                    type="text"
                    value={phone}
                    onChange={this.onChangePhone}
                    placeholder={OFFER_MODAL_ADDRESS_PHONE_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_PHONE_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </ContentContainer>
      </div>
    );
  }
}
