/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

import ContentContainer from './ContentContainer';
import StepWizardStepAbstract from '../../Common/StepWizard/StepWizardStepAbstract';

import {
  OFFER_MODAL_PRINT_MASTER_TITLE,
  OFFER_MODAL_PRINT_MASTER_PER_CLIENT_LOCATION_LABEL,
  OFFER_MODAL_PRINT_MASTER_FILE_LABEL,
  OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_PER_CLIENT_LOCATION_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER,
  NO_CLIENT_LOCATIONS_SELECTED,
  OFFER_MODAL_PRINT_USE_LAYOUT_LABEL,
  OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER,
} from '../../../constants/labels';
import { EXTRA_COPIES_ARRAY } from '../../../constants/constants';

import {
  PrintDataProps,
  PrintDataState,
  PrintFileItemProps,
  PrintFileItemState,
} from '../../../@types/OrderModal.d';
import { MasterFile, ExtraCopy, LayoutSelection } from '../../../@types/Data.d';

/**
 * Component to specify the print related information for
 * the current order
 */
class PrintFileItem extends React.Component<
  PrintFileItemProps,
  PrintFileItemState
> {
  constructor(props: PrintFileItemProps) {
    super(props);

    this.state = { useLayout: false };

    this.onChangeUseLayout = this.onChangeUseLayout.bind(this);
  }

  /**
   * On change listener for the use layout switch
   * Switch between the use of pdf files or layouts
   * made with the layout design section of FPP (FPL)
   *
   * @param event
   */
  onChangeUseLayout(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ useLayout: event.currentTarget.checked });
  }

  render(): JSX.Element {
    const {
      clientLocation,
      masterFileName,
      clientLayouts,
      onChangeMasterFiles,
      onChangeLayoutSelection,
    } = this.props;
    const { useLayout } = this.state;

    return (
      <Row className="print-item-container">
        <Col xs={4} xl={2} className="custom-text-wrap clientLocation-name p-0">
          {clientLocation.name}
        </Col>
        <Col xs={4} xl={5}>
          {useLayout ? (
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              isSearchable={false}
              options={clientLayouts.map((clientLayout) => ({
                value: clientLayout.id,
                label: clientLayout.name,
              }))}
              placeholder={OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER}
              onChange={(layout: any) =>
                onChangeLayoutSelection(layout, clientLocation.id)
              }
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              closeMenuOnScroll={false}
            />
          ) : (
            <Form.File
              id={`clientLocation-${clientLocation.id}-master-file-input`}
              data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
              accept=".pdf"
              label={masterFileName}
              custom
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeMasterFiles(event, clientLocation.id)
              }
            />
          )}
        </Col>
        <Col xs={4} xl={2}>
          <Form.Check
            type="switch"
            id={`use-layout${clientLocation.id}-switch`}
            label={OFFER_MODAL_PRINT_USE_LAYOUT_LABEL}
            checked={useLayout}
            onChange={this.onChangeUseLayout}
          />
        </Col>
      </Row>
    );
  }
}

/**
 * Container to manage print related information for the current order
 */
export default class PrintData extends StepWizardStepAbstract<
  PrintDataProps,
  PrintDataState
> {
  constructor(props: PrintDataProps) {
    super(props);

    this.state = { useLayout: false, formValidated: false };

    this.onChangePrintPerClientLocation = this.onChangePrintPerClientLocation.bind(
      this
    );
    this.onChangeExtraCopiesPerClientLocation = this.onChangeExtraCopiesPerClientLocation.bind(
      this
    );
    this.onChangeMasterFiles = this.onChangeMasterFiles.bind(this);
    this.onChangeExtraCopies = this.onChangeExtraCopies.bind(this);
    this.onChangeUseLayout = this.onChangeUseLayout.bind(this);
    this.onChangeLayoutSelection = this.onChangeLayoutSelection.bind(this);
  }

  /**
   * On change listener for print per location switch
   * Enables to use separate print files/layouts for
   * each client location of the current selection
   *
   * @param event
   */
  onChangePrintPerClientLocation(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { changePrintPerClientLocation } = this.props;

    changePrintPerClientLocation(event.currentTarget.checked);
  }

  /**
   * On change listener for extra copies per client location switch
   * Enables to specify the amount of extra copies for
   * each client location of the current selection
   *
   * @param event
   */
  onChangeExtraCopiesPerClientLocation(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { changeExtraCopiesPerClientLocation } = this.props;

    changeExtraCopiesPerClientLocation(event.currentTarget.checked);
  }

  /**
   * Change listener for the master file input
   *
   * @param event
   * @param clientLocationId
   */
  onChangeMasterFiles(
    event: React.ChangeEvent<HTMLInputElement>,
    clientLocationId: number
  ): void {
    const { changeMasterFiles } = this.props;

    event.stopPropagation();
    event.preventDefault();

    if (event.currentTarget.files !== null)
      changeMasterFiles({
        masterFile: event.currentTarget.files[0],
        clientLocationId,
      } as MasterFile);
  }

  /**
   * Change listener for the extra copies select
   *
   * @param data
   * @param clientLocationId
   */
  onChangeExtraCopies(data: any, clientLocationId: number): void {
    const { changeExtraCopies } = this.props;

    if (data && data !== null)
      changeExtraCopies({
        extraCopies: data.value,
        clientLocationId,
      } as ExtraCopy);
  }

  /**
   * On change listener for the layout select
   *
   * @param layout
   * @param clientLocationId
   */
  onChangeLayoutSelection(layout: any, clientLocationId: number): void {
    if (layout && layout !== null) {
      const { changeMasterFiles } = this.props;

      const layoutId = layout.value as number;

      changeMasterFiles({ layoutId, clientLocationId } as LayoutSelection);
    }
  }

  /**
   * On change use layout switch to use layouts from the layout designer
   * instead of a print file
   *
   * @param event
   */
  onChangeUseLayout(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ useLayout: event.currentTarget.checked });
  }

  render(): JSX.Element {
    const {
      client,
      printPerClientLocation,
      selectedClientLocations,
      clientLayouts,
      extraCopriesPerClientLocation,
      masterFiles,
    } = this.props;
    const { useLayout, formValidated } = this.state;

    return (
      <div>
        <Form
          ref={this.formRef}
          id="print-data-form"
          validated={formValidated}
          noValidate
        >
          <ContentContainer title={OFFER_MODAL_PRINT_MASTER_TITLE}>
            {client && (
              <>
                <Form.Row>
                  <Col xs={12} xl={6} className="switch-container">
                    <Form.Check
                      type="switch"
                      id="print-per-clientLocation-switch"
                      label=""
                      checked={printPerClientLocation}
                      onChange={this.onChangePrintPerClientLocation}
                    />
                    <div className="sub-section-title">
                      {OFFER_MODAL_PRINT_MASTER_PER_CLIENT_LOCATION_LABEL}
                    </div>
                  </Col>
                </Form.Row>
                <div className="separator" />
              </>
            )}
            <Form.Row>
              <Col>
                {printPerClientLocation ? (
                  selectedClientLocations.length > 0 ? (
                    selectedClientLocations.map((clientLocation) => (
                      <PrintFileItem
                        clientLocation={clientLocation}
                        clientLayouts={clientLayouts}
                        onChangeLayoutSelection={this.onChangeLayoutSelection}
                        masterFileName={
                          (masterFiles.find(
                            (file) =>
                              file.clientLocationId === clientLocation.id
                          ) as MasterFile)?.masterFile?.name ?? ''
                        }
                        onChangeMasterFiles={this.onChangeMasterFiles}
                      />
                    ))
                  ) : (
                    <Col className="empty-list-container">
                      {NO_CLIENT_LOCATIONS_SELECTED}
                    </Col>
                  )
                ) : (
                  <Row className="print-item-container">
                    <Col
                      xs={6}
                      xl={2}
                      className="custom-text-wrap clientLocation-name p-0"
                    >
                      {OFFER_MODAL_PRINT_MASTER_FILE_LABEL}
                    </Col>
                    <Col xs={6} xl={5} className="p-0">
                      {useLayout ? (
                        <Select
                          // blurInputOnSelect
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={clientLayouts.map((clientLayout) => ({
                            value: clientLayout.id,
                            label: clientLayout.name,
                          }))}
                          placeholder={
                            OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER
                          }
                          onChange={(layout: any) =>
                            this.onChangeLayoutSelection(layout, -1)
                          }
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnScroll={false}
                        />
                      ) : (
                        <Form.File
                          id="master-file-input"
                          data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
                          accept=".pdf"
                          label={
                            (masterFiles.find(
                              (file) => file.clientLocationId === -1
                            ) as MasterFile)?.masterFile.name ?? ''
                          }
                          custom
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeMasterFiles(event, -1)}
                        />
                      )}
                    </Col>
                    <Col xs={4} xl={2}>
                      <Form.Check
                        type="switch"
                        id="use-layout--1-switch"
                        label={OFFER_MODAL_PRINT_USE_LAYOUT_LABEL}
                        checked={useLayout}
                        onChange={this.onChangeUseLayout}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Form.Row>
          </ContentContainer>
          <ContentContainer title={OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL}>
            {client && (
              <>
                <Form.Row>
                  <Col xs={12} xl={6} className="switch-container">
                    <Form.Check
                      type="switch"
                      id="extra-copies-per-clientLocation-switch"
                      label=""
                      checked={extraCopriesPerClientLocation}
                      onChange={this.onChangeExtraCopiesPerClientLocation}
                    />
                    <div className="sub-section-title">
                      {OFFER_MODAL_PRINT_EXTRA_COPIES_PER_CLIENT_LOCATION_LABEL}
                    </div>
                  </Col>
                </Form.Row>
                <div className="separator" />
              </>
            )}
            <Form.Row>
              <Col>
                {extraCopriesPerClientLocation ? (
                  selectedClientLocations.length > 0 ? (
                    selectedClientLocations.map((clientLocation) => (
                      <Row className="print-item-container">
                        <Col
                          xs={6}
                          xl={2}
                          className="custom-text-wrap clientLocation-name p-0"
                        >
                          {clientLocation.name}
                        </Col>
                        <Col xs={6} xl={2} className="p-0">
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            options={EXTRA_COPIES_ARRAY}
                            onChange={(data) =>
                              this.onChangeExtraCopies(data, clientLocation.id)
                            }
                            styles={{
                              menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            placeholder={
                              OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                            }
                          />
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Col className="empty-list-container">
                      {NO_CLIENT_LOCATIONS_SELECTED}
                    </Col>
                  )
                ) : (
                  <Row className="print-item-container">
                    <Col
                      xs={6}
                      xl={2}
                      className="custom-text-wrap clientLocation-name p-0"
                    >
                      {OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL}
                    </Col>
                    <Col xs={6} xl={2} className="p-0">
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        options={EXTRA_COPIES_ARRAY}
                        onChange={(data) => this.onChangeExtraCopies(data, -1)}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        placeholder={
                          OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Form.Row>
          </ContentContainer>
        </Form>
      </div>
    );
  }
}
