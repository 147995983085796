import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';

import { HashRouter as Router } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { PARAM_TOKEN } from './constants/network';

const location = window.location.toString();
const params = location.split('?');

let token;

if (params.length > 1) {
  const values = params[1].split('&');

  // Iterate through the url parameters and set the according
  // configuration variables
  values.forEach((param) => {
    const paramPair = param.split('=', 2);
    const [key, value] = paramPair;

    switch (key.toLowerCase()) {
      case PARAM_TOKEN:
        token = value;
        break;

      default:
    }
  });
}

ReactDOM.render(
  <CookiesProvider>
    <Router>
      <App token={token} />
    </Router>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
