import '../../../styles/AreaList.scss';

import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import AreaIconContainer from './AreaIcons';
import CirculationItem from '../../Common/CirculationItem';
import CustomTooltip from '../../Common/CustomTooltip';

import { sortAreas } from '../../../util/sortUtil';
import {
  LOCALITIES_TOOLTIP,
  ADDITIONAL_AREA_TOLLTIP,
} from '../../../constants/labels';

import { AreaListPorps, AreaListItemProps } from '../../../@types/AreaList.d';

/**
 * A list item that represents a selected area
 *
 * @param props
 * @returns
 */
const AreaListItem: React.FC<AreaListItemProps> = (
  props: AreaListItemProps
) => {
  const toolTipTarget = React.createRef<any>();
  const {
    additionalArea,
    area,
    weekpart,
    parentName,
    parentAreaKey,
    summary,
  } = props;
  const showWarningIcon: boolean =
    !area.localities.every((locality) => locality.selected) || additionalArea;
  const coverage = (area.coverage * 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <ListGroup.Item
      as="div"
      className={`${additionalArea ? 'additional-area' : 'area-item'}`}
    >
      <Row ref={toolTipTarget} className="no-gutters">
        <Col xs={1} sm={1} md={1} className="p-0 pl-2">
          {showWarningIcon && (
            <CustomTooltip
              tooltipId={`area-${area.id}-warn`}
              content={
                additionalArea
                  ? ADDITIONAL_AREA_TOLLTIP(parentName, parentAreaKey)
                  : LOCALITIES_TOOLTIP
              }
            >
              <div key={area.areaKey} className="icon-warn tooltip-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
            </CustomTooltip>
          )}
        </Col>
        <Col xs={2} sm={2} md={2} className="p-0">
          {area.areaKey}
        </Col>
        <Col className="custom-text-wrap">
          {`${area.areaName}${area.coverage !== 1 ? ` (${coverage}%)` : ''}`}
        </Col>
        {summary && area.distributionType === 'INSERT' && (
          <>
            <Col xs={2} className="px-1">
              <CustomTooltip
                tooltipId={`area-${area.id}-main-org`}
                content={area.mainOrganisation ?? ''}
              >
                {area.distributorLogo ? (
                  <img
                    className="w-100"
                    src={`data:image/gif;base64,${area.distributorLogo}`}
                    alt={area.mainOrganisation}
                  />
                ) : (
                  <div className="w-100 custom-text-wrap">
                    {area.mainOrganisation}
                  </div>
                )}
              </CustomTooltip>
            </Col>
            <Col xs={2} className="px-1">
              <CustomTooltip
                tooltipId={`area-${area.id}-dist-org`}
                content={area.distributorTitle ?? ''}
              >
                {area.distributorLogo ? (
                  <img
                    className="w-100"
                    src={`data:image/gif;base64,${area.distributorLogo}`}
                    alt={area.distributorTitle}
                  />
                ) : (
                  <div className="w-100 custom-text-wrap">
                    {area.distributorTitle}
                  </div>
                )}
              </CustomTooltip>
            </Col>
          </>
        )}
        <Col xs={summary ? 2 : 3} className="p-0">
          <CirculationItem
            weekpart={weekpart}
            areaKey={area.areaKey}
            distributionType={area.distributionType}
            distributionTitle={area.distributorTitle}
            circulation={area.circulation}
            areaWeekpart={area.weekpart}
          />
        </Col>
        {!summary && (
          <Col xs={summary ? 1 : 2} className="text-right pr-2">
            <AreaIconContainer
              additionalArea={additionalArea}
              area={area}
              summary={summary}
            />
          </Col>
        )}
      </Row>
      {area.additionalAreas.length > 0 && (
        <ListGroup>
          {sortAreas(area.additionalAreas).map((additionalArea_) => (
            <AreaListItem
              key={additionalArea_.areaKey}
              area={additionalArea_}
              additionalArea
              weekpart={weekpart}
              parentName={area.areaName}
              parentAreaKey={area.areaKey}
              summary={summary}
            />
          ))}
        </ListGroup>
      )}
    </ListGroup.Item>
  );
};

/**
 * A list holding the selected areas represented by its area list items
 *
 * @param props
 * @returns
 */
const AreaList: React.FC<AreaListPorps> = (props: AreaListPorps) => {
  const { areas, weekpart, show, summary } = props;

  /**
   * Calculate max height for flexbox
   *
   * @returns
   */
  const getMaxHeight = (): number =>
    areas.reduce((acc, area) => {
      let rAcc = acc;
      rAcc += 54 + 40 * area.additionalAreas.length;
      return rAcc;
    }, 0);

  return (
    <ListGroup
      className={`area-list collapse-content ${
        show ? 'shown' : 'hidden'
      } height`}
      style={{ maxHeight: getMaxHeight() }}
    >
      {sortAreas(areas).map((area) => (
        <AreaListItem
          key={area.areaKey}
          additionalArea={false}
          area={area}
          weekpart={weekpart}
          summary={summary}
        />
      ))}
    </ListGroup>
  );
};

export default AreaList;
