import { ClientLocation, Product, Area } from '../@types/Data.d';
import {
  FPMMessageType,
  FPMMessagePayload,
  FPMMessage,
  HideClientLocationSelectionPayload,
  LayoutMessageType,
  LayoutMessagePayload,
  LayoutMessage,
  LayoutMessageNavType,
} from '../@types/MessageTypes.d';

/**
 * Sends a message to fpm
 *
 * @param type
 * @param payload
 * @returns
 */
export const sendFPMMessage = (
  type: FPMMessageType,
  payload: FPMMessagePayload
): void => {
  const pPayload = { type, payload } as FPMMessage;

  const mapElement = document.getElementById('map') as HTMLIFrameElement;

  if (mapElement === null || mapElement.contentWindow === null) return;

  mapElement.contentWindow.postMessage(pPayload, '*');
};

/**
 * Send a show localities modal message
 *
 * @param area
 * @returns
 */
export const showLocalitiesModal = (area: Area): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_UPDATE_LOCALITIES, area);

/**
 * Send a remove area message
 *
 * @param area
 * @returns
 */
export const removeArea = (area: Area): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_REMOVE_AREA, area);

/**
 * Send a remove all areas message
 *
 * @returns
 */
export const removeAllAreas = (): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_REMOVE_ALL_AREAS, '');

/**
 * Send a remove client location message
 *
 * @param clientLocation
 * @returns
 */
export const removeClientLocation = (clientLocation: ClientLocation): void =>
  sendFPMMessage(
    FPMMessageType.FPM_MESSAGE_TYPE_REMOVE_CLIENT_LOCATION,
    clientLocation
  );

/**
 * Send a change product message
 *
 * @param product
 * @returns
 */
export const changeProduct = (product: Product): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_CHANGE_PRODUCT, product);

/**
 * Send a add order message
 *
 * @param order
 * @returns
 */
export const addOrder = (order: any): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_ADD_ORDER, order);

/**
 * Send a update localities message
 *
 * @param area
 * @returns
 */
export const updateLocalities = (area: Area): void =>
  sendFPMMessage(FPMMessageType.FPM_MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL, {
    ...area,
    ...{ feature: undefined, layer: undefined },
  });

/**
 * Send a hide client location selection message
 *
 * @param clientLocation
 * @param show
 * @returns
 */
export const hideClientLocationSelection = (
  clientLocation: ClientLocation,
  show: boolean
): void =>
  sendFPMMessage(
    FPMMessageType.FPM_MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION,
    {
      clientLocationId: clientLocation.id,
      show,
    } as HideClientLocationSelectionPayload
  );

/**
 * Send a message to fpl
 *
 * @param type
 * @param payload
 * @returns
 */
export const sendLayoutMessage = (
  type: LayoutMessageType,
  payload: LayoutMessagePayload
): void => {
  const pPayload = { type, payload } as LayoutMessage;

  const layoutElement = document.getElementById('layout') as HTMLIFrameElement;

  if (layoutElement === null || layoutElement.contentWindow === null) return;

  layoutElement.contentWindow.postMessage(pPayload, '*');
};

/**
 * Send a navigate message
 *
 * @param target
 * @returns
 */
export const navigateLayout = (target?: LayoutMessageNavType): void =>
  sendLayoutMessage(
    LayoutMessageType.LAYOUT_MESSAGE_TYPE_NAVIGATE,
    target ?? '/'
  );
