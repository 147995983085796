import {
  ClientLocation,
  AreaSendFormat,
  LocalitySendFormat,
  ClientLocationSendFormat,
  MasterFile,
  ExtraCopy,
  LayoutSelection,
  Area,
  Locality,
} from '../@types/Data.d';

/**
 * Convert a file to base64
 *
 * @param file
 * @returns
 */
export const getBas64FromFile = (file?: File): Promise<any> | undefined => {
  if (!file) return;
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  }).then(
    (result) => result as string,
    (error) => error
  );
};

/**
 * Get a locality in send format
 *
 * @param locality
 * @returns
 */
export const getLocalitySend = (locality: Locality): LocalitySendFormat =>
  ({
    id: locality.id,
    localityKey: locality.localityKey,
    selected: locality.selected,
  } as LocalitySendFormat);

/**
 * Get an array of localities in send format
 *
 * @param localities
 * @returns
 */
export const getLocalitiesSend = (
  localities: Locality[]
): LocalitySendFormat[] =>
  localities.map((locality) =>
    getLocalitySend(locality)
  ) as LocalitySendFormat[];

/**
 * Get an area in send format
 *
 * @param area
 * @returns
 */
export const getAreaSend = (area: Area): AreaSendFormat =>
  ({
    areaKey: area.areaKey,
    countryCode: area.countryCode,
    type: area.type,
    localities: getLocalitiesSend(area.localities),
  } as AreaSendFormat);

/**
 * Get an array of areas in send format
 *
 * @param areas
 * @returns
 */
export const getAreasSend = (areas: Area[]): AreaSendFormat[] =>
  areas.map((area) => getAreaSend(area)) as AreaSendFormat[];

/**
 * Get an array of client locations in send format
 *
 * @param clientLocations
 * @param masterFiles
 * @param extraCopies
 * @param printPerClientLocation
 * @returns
 */
export const getClientLocationsSend = (
  clientLocations: ClientLocation[],
  masterFiles: (MasterFile | LayoutSelection)[],
  extraCopies: ExtraCopy[],
  printPerClientLocation: boolean
): Promise<ClientLocationSendFormat>[] =>
  clientLocations.map(async (clientLocation) => {
    let masterFile;
    let layoutId;

    const match = masterFiles.find(
      (file) => file.clientLocationId === clientLocation.id
    );

    if (printPerClientLocation && (match as MasterFile)?.masterFile)
      masterFile = (await getBas64FromFile(
        (match as MasterFile)?.masterFile
      )) as string;
    else if ((match as LayoutSelection)?.layoutId)
      layoutId = `${(match as LayoutSelection).layoutId}`;

    return {
      id: clientLocation.id,
      areas: getAreasSend(clientLocation.areas),
      masterFile: await masterFile,
      layoutId,
      extraCopies: extraCopies.find(
        (extraCopy) => extraCopy.clientLocationId === clientLocation.id
      )?.extraCopies,
    } as ClientLocationSendFormat;
  });

export const priceString = (price: string): string =>
  parseFloat(price).toLocaleString('en-de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
