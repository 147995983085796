import '../../styles/LayoutDesign.scss';

import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { REQUEST_IDENTIFIER_LOAD_LAYOUT_IFRAME } from '../../constants/constants';

import {
  LayoutDesignContainerProps,
  LayoutDesignContainerState,
} from '../../@types/LayoutDesign.d';

/**
 * A container component containing the layout design tool CustomerCanvas/FPL
 */
export default class LayoutContainer extends React.Component<
  LayoutDesignContainerProps,
  LayoutDesignContainerState
> {
  iFrameRef = React.createRef<HTMLIFrameElement>();

  constructor(props: LayoutDesignContainerProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    const { enableLoadingOverlay } = this.props;
    const { current } = this.iFrameRef;

    if (current === null) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_LAYOUT_IFRAME);

    current.onload = () =>
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_LAYOUT_IFRAME);
  }

  render(): JSX.Element {
    const { iFrameURL } = this.props;

    return (
      <Row className="layout-container">
        <Col className="layout-design-container">
          {iFrameURL.length > 0 && (
            <iframe
              ref={this.iFrameRef}
              id="layout"
              title="fpm-layout"
              className="layout-iframe"
              frameBorder="0"
              allowFullScreen
              allow="fullscreen"
              src={iFrameURL}
            />
          )}
        </Col>
      </Row>
    );
  }
}
