import axios from 'axios';

import {
  parseUser,
  parseClients,
  parseFullClient,
  extractPrices,
  extractClientLayouts,
  extractClientLocations,
} from './responseUtil';

import {
  PATH_LOGIN,
  PATH_CHANGE_PASSWORD,
  PATH_USERCLIENTS,
  PATH_CLIENT_DATA,
  PATH_CALCULATION_FPP,
  PATH_CLIENT_LAYOUTS_GET,
  PATH_TOKEN_AUTH,
  PATH_CLIENT_LOCATIONS,
  PATH_ORDER_REQUEST,
} from '../constants/network';

import config from '../config';

import {
  RequestPayload,
  Client,
  User,
  Product,
  Weekpart,
  ClientLocationSendFormat,
  PriceResult,
  AdditionalOptions,
  ClientLayout,
  StateFileState,
  ClientLocation,
} from '../@types/Data.d';

// the axios instance which is used for api calls
export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
});

// the default headers
const headers = {
  'X-API-Key-Token': process.env.REACT_APP_FRA_API_KEY,
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

/**
 * Prints an error to the console
 *
 * @param error
 * @returns
 */
export const printError = (error: any): string => {
  // eslint-disable-next-line no-console
  if (config.printToConsole) console.log(error);
  return (error.response || error.request).status;
};

/**
 * Get all clients of the user (slim)
 *
 * @param user
 * @returns
 */
export const getClients = (user: string): Promise<Client[] | string> =>
  axiosClient
    .get(PATH_USERCLIENTS, {
      params: { user },
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => parseClients(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Loggs a user in
 *
 * @param email
 * @param password
 * @returns
 */
export const getLogin = (
  email: string,
  password: string
): Promise<User | string> =>
  axiosClient
    .get(PATH_LOGIN, {
      params: { user: email, password },
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => parseUser(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Login with a token
 *
 * @param token
 * @returns
 */
export const getAuthByToken = (token: string): Promise<User | string> =>
  axiosClient
    .get(PATH_TOKEN_AUTH, {
      params: { token },
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => parseUser(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Change the password
 *
 * @param user
 * @param oldPassword
 * @param newPassword
 * @returns
 */
export const putChangePassword = (
  user: string,
  oldPassword: string,
  newPassword: string
): Promise<string | number> =>
  axiosClient
    .put(
      PATH_CHANGE_PASSWORD,
      {},
      {
        params: { user, oldPassword, newPassword },
        headers,
        validateStatus: (status: number) => status < 300,
      }
    )
    .then(
      (response) => response.status,
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Get a client object (full)
 *
 * @param clientUUID
 * @returns
 */
export const getClientData = (clientUUID: string): Promise<string | Client> =>
  axiosClient
    .get(PATH_CLIENT_DATA(clientUUID), {
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => parseFullClient(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

/**
 * Gets all the clients locations
 *
 * @param clientUUID
 * @returns
 */
export const getClientLocations = (
  clientUUID: string
): Promise<string | ClientLocation[]> =>
  axiosClient
    .get(PATH_CLIENT_LOCATIONS(clientUUID), {
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => extractClientLocations(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));

// export const postOfferRequest = (
//   requestPayload: RequestPayload
// ): Promise<string | number> =>
//   axiosClient
//     .post(PATH_OFFER_REQUEST, requestPayload, {
//       headers,
//       validateStatus: (status: number) => status < 300,
//     })
//     .then(
//       (response) => response,
//       (error) => {
//         printError(error);
//         return error;
//       }
//     )
//     .catch((error) => {
//       printError(error);
//       return error;
//     });

/**
 * Submits an oder request
 *
 * @param requestPayload
 * @returns
 */
export const postOrderRequest = (
  requestPayload: RequestPayload
): Promise<string | number> =>
  axiosClient
    .post(PATH_ORDER_REQUEST, requestPayload, {
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => response,
      (error) => {
        printError(error);
        return error;
      }
    )
    .catch((error) => {
      printError(error);
      return error;
    });

/**
 * Gets the total price of the given selection
 *
 * @param locations
 * @param weekpart
 * @param printPerClientLocation
 * @param product
 * @param additionalOptions
 * @returns
 */
export const getTotalPrice = (
  locations: ClientLocationSendFormat[],
  weekpart: Weekpart,
  printPerClientLocation: boolean,
  product?: Product,
  additionalOptions?: AdditionalOptions
): Promise<void | PriceResult> =>
  axiosClient
    .post(
      PATH_CALCULATION_FPP,
      {
        locations,
        productId: product?.id ?? -1,
        weekpart,
        printPerClientLocation,
        additionalOptions,
      },
      { headers }
    )
    .then((response) => extractPrices(response.data) as PriceResult);

/**
 * Returns all client layouts
 *
 * @param client
 * @param state
 * @returns
 */
export const getClientLayouts = (
  client: Client,
  state: StateFileState
): Promise<string | ClientLayout[]> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUTS_GET(client.uuid), {
      params: { state },
      headers,
      validateStatus: (status: number) => status < 300,
    })
    .then(
      (response) => extractClientLayouts(response.data),
      (error) => printError(error)
    )
    .catch((error) => printError(error));
