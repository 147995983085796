import '../../styles/Header.scss';

import * as React from 'react';

import { Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

import BurgerIcon from './MainMenu/BurgerIcon';

import {
  HEADER_LOGIN_BUTTON,
  HEADER_CLIENT_LABEL,
  HEADER_CLIENT_PLACEHOLDER,
} from '../../constants/labels';

import {
  HeaderProps,
  HeaderState,
  HeaderLoginProps,
  HeaderMenuProps,
  ClientSelect,
} from '../../@types/Header.d';

// import userPlaceholderLight from '../../resources/img/userPlaceholderLight.png';
import userPlaceholderDark from '../../resources/img/userPlaceholderDark.png';

/**
 * Component which holds a login button if no user is logged in
 *
 * @param props
 * @returns
 */
const HeaderLogin: React.FC<HeaderLoginProps> = (props: HeaderLoginProps) => {
  const { showLoginModal } = props;

  /**
   * On click listener for the login button
   *
   * @param event
   */
  const onClickLogin = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    showLoginModal(true);
    event.preventDefault();
  };

  return (
    <Col className="header-login-container">
      <Button className="ci-button" onClick={onClickLogin}>
        {HEADER_LOGIN_BUTTON}
      </Button>
    </Col>
  );
};

/**
 * Menu shown at the top of the page which holds some functionality
 *
 * @param props
 * @returns
 */
const HeaderMenu: React.FC<HeaderMenuProps> = (props: HeaderMenuProps) => {
  const {
    clients,
    selectedClient,
    menuShown,
    changeSelectedClient,
    showMainMenu,
    user,
  } = props;

  // Get either the users avatar or a placeholder if not available
  let avatarSrc;
  if (user && user.avatar) avatarSrc = user.avatar;
  else avatarSrc = userPlaceholderDark;

  // Convert the clients to a react-select friendly format
  const clientSelection: ClientSelect[] =
    clients?.map((client) => ({
      label: client.name,
      value: client,
    })) ?? ([] as ClientSelect[]);

  // On change listener for the client select
  const onChangeClientSelect = (selection: any): void => {
    if (selection && selection !== null && changeSelectedClient) {
      changeSelectedClient(selection.value);
    }
  };

  /**
   * On click listener for the menu button
   *
   * @param event
   */
  const onClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    showMainMenu(!menuShown);
  };

  /**
   * On key down listener for the menu button
   *
   * @param event
   * @returns
   */
  const onKeyDownMenu = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    showMainMenu(!menuShown);
  };

  // Find the currently selected client and set it as default value
  // for the client select
  const selectValue = clientSelection.find(
    (client) => client.value.id === selectedClient?.id
  );

  return (
    <div className="header-menu-container">
      <div className="left">
        <div className="user-info">
          <div className="burger-icon">
            <BurgerIcon
              close={menuShown}
              dark
              onClick={onClickMenu}
              onKeyDown={onKeyDownMenu}
            />
          </div>
          <div className="user-avatar">
            <img
              src={avatarSrc}
              alt={user ? `${user.prename} ${user.lastname}` : 'avatar'}
            />
          </div>
          <div className="user-name">{`${user.prename} ${user.lastname}`}</div>
        </div>
      </div>
      <div className="right">
        {user && clients && selectedClient && (
          <div className="client-select">
            <div className="client-select-label">{HEADER_CLIENT_LABEL}</div>
            <div className="client-select-container">
              <Form.Group>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  blurInputOnSelect
                  isSearchable
                  hasVaue
                  placeholder={HEADER_CLIENT_PLACEHOLDER}
                  options={clientSelection}
                  value={selectValue}
                  onChange={onChangeClientSelect}
                />
              </Form.Group>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * The container to hold the header. Renders the login component or
 * the normal header depending on if there is an logged in user
 */
export default class HeaderContainer extends React.Component<
  HeaderProps,
  HeaderState
> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const {
      user,
      clients,
      selectedClient,
      menuShown,
      changeSelectedClient,
      showLoginModal,
      showMainMenu,
    } = this.props;

    return (
      <Row className="header-container">
        {user ? (
          <HeaderMenu
            user={user}
            clients={clients}
            selectedClient={selectedClient}
            menuShown={menuShown}
            changeSelectedClient={changeSelectedClient}
            showMainMenu={showMainMenu}
          />
        ) : (
          <HeaderLogin showLoginModal={showLoginModal} />
        )}
      </Row>
    );
  }
}
