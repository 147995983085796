import moment from 'moment';
import {
  ClientLocation,
  KaufDaItem,
  SocialMedia,
  SocialMediaItem,
  SocialMediaTargetGroup,
  AdditionalOptions,
  AdditionalOptionSettings,
  SocialMediaItemSettings,
  KaufDaItemSettings,
} from '../@types/Data.d';

/**
 * Generate a stub for social media items
 *
 * @param socialMediaSetting
 * @param clientLocations
 * @param platform
 * @returns
 */
export const generateSocialMediaStubs = (
  socialMediaSetting: SocialMediaItemSettings,
  clientLocations: ClientLocation[],
  platform: SocialMedia
): SocialMediaItem[] =>
  clientLocations.map(
    (clientLocation: ClientLocation) =>
      ({
        type: 'SOCIALMEDIA',
        selected: false,
        clientLocationId: clientLocation.id,
        clientLocationName: clientLocation.name,
        platform,
        range:
          socialMediaSetting.mode === 'DEFAULT'
            ? socialMediaSetting.defaultRange
            : socialMediaSetting.presetSelectionRanges[0],
        duration:
          socialMediaSetting.mode === 'DEFAULT'
            ? socialMediaSetting.defaultDuration
            : socialMediaSetting.presetSelectionDurations[0],
        budget:
          socialMediaSetting.mode === 'DEFAULT'
            ? socialMediaSetting.defaultBudget
            : socialMediaSetting.presetSelectionBudgets[0],
        targetGroups:
          (socialMediaSetting.mode === 'DEFAULT'
            ? socialMediaSetting.defaultTargetGroups
            : socialMediaSetting.presetSelectionTargetGroups) ??
          ([] as SocialMediaTargetGroup[]),
      } as SocialMediaItem)
  ) ?? ([] as SocialMediaItem[]);

/**
 * Generate a stub for kauf da items
 *
 * @param kaufDaSetting
 * @param clientLocations
 * @returns
 */
export const generateKaufDaStubs = (
  kaufDaSetting: KaufDaItemSettings,
  clientLocations: ClientLocation[]
): KaufDaItem[] =>
  clientLocations.map(
    (clientLocation: ClientLocation) =>
      ({
        type: 'KAUFDA',
        selected: false,
        clientLocationId: clientLocation.id,
        clientLocationName: clientLocation.name,
        range:
          kaufDaSetting.mode === 'DEFAULT'
            ? kaufDaSetting.defaultRange
            : kaufDaSetting.presetSelectionRanges[0],
        duration:
          kaufDaSetting.mode === 'DEFAULT'
            ? kaufDaSetting.defaultDuration
            : kaufDaSetting.presetSelectionDurations[0],
        alternateHomepage:
          kaufDaSetting.mode === 'DEFAULT'
            ? kaufDaSetting.defaultHomepage
            : kaufDaSetting.presetSelectionHomepages[0],
        startDate: moment().add(3, 'days'),
      } as KaufDaItem)
  ) ?? ([] as KaufDaItem[]);

/**
 * Generate stubs for addtional options
 *
 * @param clientLocations
 * @param additionalOptions
 * @returns
 */
export const generateAdditionalOptionsStub = (
  clientLocations: ClientLocation[],
  additionalOptions?: AdditionalOptionSettings[]
): AdditionalOptions => {
  const kaufDaSettings = additionalOptions?.find(
    (o) => o.type === 'KAUFDA'
  ) as KaufDaItemSettings;
  const facebookSettings = additionalOptions?.find(
    (o) =>
      o.type === 'SOCIALMEDIA' &&
      (o as SocialMediaItemSettings).platform === SocialMedia.FACEBOOK
  ) as SocialMediaItemSettings;
  const instagramSettings = additionalOptions?.find(
    (o) =>
      o.type === 'SOCIALMEDIA' &&
      (o as SocialMediaItemSettings).platform === SocialMedia.INSTAGRAM
  ) as SocialMediaItemSettings;
  const tikTokSettings = additionalOptions?.find(
    (o) =>
      o.type === 'SOCIALMEDIA' &&
      (o as SocialMediaItemSettings).platform === SocialMedia.TIKTOK
  ) as SocialMediaItemSettings;

  return clientLocations
    ? {
        ...(kaufDaSettings?.enabled
          ? {
              kaufDaItems: generateKaufDaStubs(kaufDaSettings, clientLocations),
            }
          : undefined),
        ...(facebookSettings?.enabled
          ? {
              facebookItems: generateSocialMediaStubs(
                facebookSettings,
                clientLocations,
                SocialMedia.FACEBOOK
              ),
            }
          : undefined),
        ...(instagramSettings?.enabled
          ? {
              instagramItems: generateSocialMediaStubs(
                instagramSettings,
                clientLocations,
                SocialMedia.INSTAGRAM
              ),
            }
          : undefined),
        ...(tikTokSettings?.enabled
          ? {
              tiktokItems: generateSocialMediaStubs(
                tikTokSettings,
                clientLocations,
                SocialMedia.TIKTOK
              ),
            }
          : undefined),
      }
    : ({} as AdditionalOptions);
};
