import { ClientLocation, Area } from '../@types/Data.d';

/**
 * Sort areas by area key
 *
 * @param areas
 * @returns
 */
export const sortAreas = (areas: Area[]): Area[] =>
  areas.sort((areaA: Area, areaB: Area) => {
    if (areaA.areaKey.toLocaleLowerCase() < areaB.areaKey.toLocaleLowerCase())
      return -1;
    if (areaA.areaKey.toLocaleLowerCase() > areaB.areaKey.toLocaleLowerCase())
      return 1;

    return 0;
  });

/**
 * Sort client locations by name
 *
 * @param clientLocations
 * @returns
 */
export const sortClientLocations = (
  clientLocations: ClientLocation[]
): ClientLocation[] =>
  clientLocations.sort(
    (clientLocationA: ClientLocation, clientLocationB: ClientLocation) => {
      if (
        clientLocationA.name.toLocaleLowerCase() <
        clientLocationB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        clientLocationA.name.toLocaleLowerCase() >
        clientLocationB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );
