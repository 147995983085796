/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

import { Row, Col, Table, Form } from 'react-bootstrap';

import ContentContainer from './ContentContainer';
import StepWizardStepAbstract from '../../Common/StepWizard/StepWizardStepAbstract';

import {
  OFFER_MODAL_SUMMARY_CONACT_TITLE,
  OFFER_MODAL_SUMMARY_BILLING_CLIENT_LOCATION_TITLE,
  OFFER_MODAL_SUMMARY_BILLING_PER_CLIENT_LOCATION_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_PER_1000_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION,
  OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL,
  NO_PHONE_NUMBER,
  NO_AREAS_SELECTED,
  OFFER_MODAL_SUMMARY_ORDER_DATA_CLIENT_LOCATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_CLIENT_LOCATION_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_START_DATE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_ON_REQUEST_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_FILE_TITLE,
  TOOLTIP_FILE_EMPTY,
} from '../../../constants/labels';

import {
  SummaryProps,
  SummaryState,
  ClientLocationTableProps,
  AreaTableProps,
  KaufDaTableProps,
} from '../../../@types/OrderModal.d';
import {
  getTotalCirculationAreas,
  getTotalCirculationClientLocations,
} from '../../../util/areaUtil';

import kaufdaLogo from '../../../resources/img/kaufdaLogo.svg';
import CustomTooltip from '../../Common/CustomTooltip';

/**
 * A separator element for the table
 *
 * @returns
 */
const TableSeparator: React.FC<{}> = () => (
  <tr>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
  </tr>
);

/**
 * A table that hold all selected client locations
 *
 * @param props
 * @returns
 */
const ClientLocationTable: React.FC<ClientLocationTableProps> = (
  props: ClientLocationTableProps
) => {
  const { selectedClientLocations, extraCopies, price } = props;

  return (
    <>
      <thead>
        <tr>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_CLIENT_LOCATION_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {selectedClientLocations?.map((clientLocation) => (
          <tr key={clientLocation.id}>
            <td className="text-left">{clientLocation.name}</td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
                extraCopies?.find(
                  (copy) => copy.clientLocationId === clientLocation.id
                )?.extraCopies
              )}
            </td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
                clientLocation.price?.circulation
              )}
            </td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
                clientLocation.price?.total
              )}
            </td>
            <td className="text-left">
              <small>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_PER_1000_LABEL(
                  clientLocation.price?.totalPerThousand
                )}
              </small>
            </td>
          </tr>
        ))}
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL}
          </td>
          <td>
            {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
              extraCopies?.reduce((acc, copy) => acc + copy.extraCopies, 0)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
              getTotalCirculationClientLocations(selectedClientLocations)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
              price?.subtotalDistribution
            )}
          </td>
          <td className="text-left">
            <small>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION}
            </small>
          </td>
        </tr>
      </tbody>
    </>
  );
};

/**
 * A table that holds all selected areas
 *
 * @param props
 * @returns
 */
const AreaTable: React.FC<AreaTableProps> = (props: AreaTableProps) => {
  const { areas, extraCopies } = props;

  return (
    <>
      <thead>
        <tr>
          <th />
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
              extraCopies?.find((copy) => copy.clientLocationId === -1)
                ?.extraCopies
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
              getTotalCirculationAreas(areas)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_ON_REQUEST_LABEL}
          </td>
          <td />
        </tr>
      </tbody>
    </>
  );
};

/**
 * Table that hold all selected kauf da options
 *
 * @param props
 * @returns
 */
const KaufDaTable: React.FC<KaufDaTableProps> = (props: KaufDaTableProps) => {
  const { kaufDaItems, selectedClientLocations, price } = props;

  return (
    <>
      <thead>
        <tr>
          <th>
            {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_CLIENT_LOCATION_TITLE}
          </th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_START_DATE_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_FILE_TITLE}</th>
        </tr>
      </thead>
      <tbody>
        {kaufDaItems
          ?.filter((kaufDaItem) => kaufDaItem.selected)
          .map((kaufDaItem) => (
            <tr key={kaufDaItem.clientLocationId}>
              <td className="text-left">
                {selectedClientLocations?.find(
                  (clientLocation) =>
                    clientLocation.id === kaufDaItem.clientLocationId
                )?.name ?? 'Keine'}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_LABEL(
                  kaufDaItem.range
                )}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_LABEL(
                  kaufDaItem.duration?.duration
                )}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL(
                  selectedClientLocations?.find(
                    (clientLocation) =>
                      clientLocation.id === kaufDaItem.clientLocationId
                  )?.price?.totalKaufDa
                )}
              </td>
              <td className="text-left start-date">
                {kaufDaItem.startDate.format('DD.MM.YYYY')}
              </td>
              <td>
                <CustomTooltip
                  tooltipId={`kaufda-item-${kaufDaItem.clientLocationId}-file`}
                  content={kaufDaItem.pdfFileName ?? TOOLTIP_FILE_EMPTY}
                >
                  <div className="filename">
                    {kaufDaItem.pdfFileName ?? TOOLTIP_FILE_EMPTY}
                  </div>
                </CustomTooltip>
              </td>
            </tr>
          )) ?? <></>}
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL}
          </td>
          <td />
          <td />
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL(
              price?.subtotalKaufDa
            )}
          </td>
          <td className="text-left">
            <small>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION}
            </small>
          </td>
        </tr>
      </tbody>
    </>
  );
};

/**
 * Container that hold all the above tables
 */
export default class Summary extends StepWizardStepAbstract<
  SummaryProps,
  SummaryState
> {
  constructor(props: SummaryProps) {
    super(props);

    this.state = { formValidated: false };
  }

  render(): JSX.Element {
    const { formValidated } = this.state;
    const {
      client,
      prename,
      lastname,
      salutation,
      email,
      phone,
      areas,
      selectedClientLocations,
      billingClientLocation,
      price,
      extraCopies,
      billingType,
      product,
      additionalOptions,
      user,
    } = this.props;

    let billingName;
    let billingStreet;
    let billingCity;

    if (billingType === 'CLIENT' && client?.billingAddress) {
      const {
        postcode,
        city,
        street,
        housenumber,
        name,
      } = client?.billingAddress;

      billingName = name;
      billingStreet = `${street} ${housenumber}`;
      billingCity = `${postcode} ${city}`;
    } else if (billingType === 'LOCATION' && billingClientLocation) {
      const {
        postcode,
        city,
        street,
        housenumber,
        name,
      } = billingClientLocation;

      billingName = name;
      billingStreet = `${street} ${housenumber}`;
      billingCity = `${postcode} ${city}`;
    }

    return (
      <div>
        <Form
          ref={this.formRef}
          id="summary-form"
          validated={formValidated}
          noValidate
        >
          <Row className="no-gutters">
            <Col xs={12} xl={6}>
              <ContentContainer title={OFFER_MODAL_SUMMARY_CONACT_TITLE}>
                <div className="summary-item-container">
                  <div className="summary-item">{`${
                    salutation ?? ''
                  } ${prename} ${lastname}`}</div>
                  <div className="summary-item">{email}</div>
                  <div className="summary-item">{phone || NO_PHONE_NUMBER}</div>
                </div>
              </ContentContainer>
            </Col>
            {user && (
              <Col xs={12} xl={6}>
                <ContentContainer
                  title={OFFER_MODAL_SUMMARY_BILLING_CLIENT_LOCATION_TITLE}
                >
                  {billingType !== 'PER_LOCATION' ? (
                    <div className="summary-item-container">
                      <div className="summary-item">{billingName}</div>
                      <div className="summary-item">{billingStreet}</div>
                      <div className="summary-item">{billingCity}</div>
                    </div>
                  ) : (
                    <div className="summary-item-container">
                      <div className="summary-item">
                        {OFFER_MODAL_SUMMARY_BILLING_PER_CLIENT_LOCATION_TITLE}
                      </div>
                    </div>
                  )}
                </ContentContainer>
              </Col>
            )}
          </Row>
          <Row className="no-gutters">
            <Col>
              <ContentContainer title={OFFER_MODAL_SUMMARY_ORDER_DATA_TITLE}>
                <div className="summary-item-container">
                  <div className="summary-item sub-section-title">
                    {product.name}
                  </div>
                </div>
                <div className="separator" />
                <Table size="sm" className="summary-table" borderless>
                  {(selectedClientLocations?.length ?? 0) > 0 && (
                    <ClientLocationTable
                      selectedClientLocations={selectedClientLocations}
                      extraCopies={extraCopies}
                      price={price}
                    />
                  )}
                  {(areas?.length ?? 0) > 0 && (
                    <AreaTable
                      areas={areas}
                      extraCopies={extraCopies}
                      price={price}
                    />
                  )}
                  {(areas?.length ?? 0) === 0 &&
                    (selectedClientLocations?.length ?? 0) === 0 && (
                      <div className="empty-list-container">
                        {NO_AREAS_SELECTED}
                      </div>
                    )}
                  {additionalOptions.kaufDaItems?.some(
                    (item) => item.selected
                  ) ? (
                    <>
                      <tbody>
                        <tr>
                          <td className="logo-container">
                            <img
                              className="additional-option-logo"
                              src={kaufdaLogo}
                              alt="kauf-da-logo"
                            />
                          </td>
                        </tr>
                        <TableSeparator />
                      </tbody>
                      <KaufDaTable
                        kaufDaItems={additionalOptions.kaufDaItems}
                        selectedClientLocations={selectedClientLocations}
                        price={price}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {(selectedClientLocations?.length ?? 0) > 0 && (
                    <tbody>
                      <tr>
                        <td className="pt-2" />
                      </tr>
                      <TableSeparator />
                      <TableSeparator />
                      <TableSeparator />
                      <tr>
                        <td className="total label pt-2">
                          {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL}
                        </td>
                        <td />
                        <td />
                        <td className="total">
                          {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
                            price?.total
                          )}
                        </td>
                        <td className="text-left">
                          <small>
                            {
                              OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION
                            }
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </ContentContainer>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
