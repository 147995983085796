import * as React from 'react';

import { Button, Col, Modal } from 'react-bootstrap';

import {
  MAIN_MENU_ITEM_LABEL_DISTRIBUTION,
  MAIN_MENU_ITEM_LABEL_HOME,
  MAIN_MENU_ITEM_LABEL_LAYOUT,
  NAVIGATION_MODAL_CONTENT,
  NAVIGATION_MODAL_TITLE,
} from '../../constants/labels';

import { NavigationModalProps } from '../../@types/Modal.d';
import { FinishActionNavType } from '../../@types/MessageTypes.d';

/**
 * A modal that launches after the finish design action of the FPL
 * Navigates to Hame/Distribution/Layout
 */
const NavigationModal: React.FC<NavigationModalProps> = (
  props: NavigationModalProps
) => {
  const { show, showModal, navigateTo } = props;

  /**
   * Hides the modal
   */
  const onHide = (): void => {
    showModal(false);
  };

  /**
   * On click listener for an item to navigate
   */
  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    target: FinishActionNavType
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    navigateTo(target);

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header>{NAVIGATION_MODAL_TITLE}</Modal.Header>
      <Modal.Body>{NAVIGATION_MODAL_CONTENT}</Modal.Body>
      <Modal.Footer>
        <Col className="p-0">
          <Button
            className="ci-button modal-footer-button"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onClick(event, '/layout')
            }
          >
            {MAIN_MENU_ITEM_LABEL_LAYOUT}
          </Button>
          <Button
            className="ci-button modal-footer-button"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onClick(event, '/distribution')
            }
          >
            {MAIN_MENU_ITEM_LABEL_DISTRIBUTION}
          </Button>
          <Button
            className="ci-button modal-footer-button"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onClick(event, '/')
            }
          >
            {MAIN_MENU_ITEM_LABEL_HOME}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default NavigationModal;
