import '../../../styles/StepWizard.scss';

import * as React from 'react';

import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_NEXT,
  BUTTON_TITLE_PREVOUS,
  BUTTON_TITLE_SUBMIT,
} from '../../../constants/labels';

import {
  StepWizardProps,
  StepWizardState,
  TitleBarProps,
} from '../../../@types/StepWizard.d';

/**
 * A title bar containing the titles of the single steps
 *
 * @param props
 * @returns
 */
const TitleBar: React.FC<TitleBarProps> = (props: TitleBarProps) => {
  const { titles, childrenRef, activeIndex } = props;

  /**
   * Check if the current steps form is valid
   * If not show a warning
   *
   * @param index
   * @returns
   */
  const checkValid = (index: number): boolean => {
    const childRef = childrenRef[index];

    if (!childRef) return true;

    const { current } = childRef;

    if (current === null) return true;

    return current.checkFormValid();
  };

  return (
    <Row className="title-bar">
      {titles.map((title, index) => (
        <Col // eslint-disable-next-line react/no-array-index-key
          key={`${title}-${index}`}
          className={`title-bar-item ${index === activeIndex ? 'active' : ''}`}
        >
          {!checkValid(index) && (
            <div className="title-bar-invalid">
              <div className="icon-bg" />
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="icon-warn"
              />
            </div>
          )}
          <div className="title-bar-title">{title}</div>
        </Col>
      ))}
    </Row>
  );
};

/**
 * A wizard style component
 */
export default class StepWizard extends React.Component<
  StepWizardProps,
  StepWizardState
> {
  constructor(props: StepWizardProps) {
    super(props);

    const { titles, children, childrenRef } = this.props;

    this.state = {
      step: 0,
      direction: 'next',
      titles: titles.filter((title, index) => children[index]),
      childrenRef: childrenRef.filter((title, index) => children[index]),
    };

    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.abort = this.abort.bind(this);

    this.getCSSAnimationClass = this.getCSSAnimationClass.bind(this);
  }

  componentDidUpdate(prevProps: StepWizardProps): void {
    const { childrenRef, children } = this.props;

    if (
      childrenRef.filter((ref) => ref.current !== null).length !==
      prevProps.childrenRef.filter((ref) => ref.current !== null).length
    )
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        childrenRef: childrenRef.filter((title, index) => children[index]),
      });
  }

  /**
   * Determine the class for slide in or out animation
   *
   * @param index
   * @returns
   */
  getCSSAnimationClass(index: number): string {
    const { step, direction } = this.state;

    if (index === 0 && step === 0 && direction === 'next') return '';

    if (index < step) return 'hidden-to-left';
    if (index > step) return 'hidden-to-right';

    if (direction === 'previous') return 'show-from-left';

    return 'show-from-right';
  }

  /**
   * Advance to next step
   *
   * @param event
   */
  nextStep(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { setFormValidated } = this.props;
    const { step, childrenRef } = this.state;

    // If step number is not bigger than length of all steps continue
    if (step + 1 < childrenRef.length)
      // Set new step number and direction to "next"
      this.setState({ step: step + 1, direction: 'next' }, () => {
        // If last step check form validity
        if (step + 1 === childrenRef.length - 1 && setFormValidated)
          setFormValidated();
      });
  }

  /**
   * Go to the previous step
   *
   * @param event
   */
  previousStep(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { step } = this.state;

    // If step number is greater 0, continue and set direction to "previous"
    if (step - 1 >= 0) this.setState({ step: step - 1, direction: 'previous' });
  }

  /**
   * On click listener for the abort button
   *
   * @param event
   */
  abort(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { abort } = this.props;

    abort();
  }

  render(): JSX.Element {
    const { children, formId, showTitleBar, checkFormValid } = this.props;
    const { step, titles, childrenRef } = this.state;

    const lastStep = step === children.filter((child) => child).length - 1;

    return (
      <div className="wizard-container">
        {showTitleBar ? (
          <TitleBar
            titles={titles}
            activeIndex={step}
            childrenRef={childrenRef}
          />
        ) : (
          <div className="wizard-title">{titles[step]}</div>
        )}
        <div className="wizard-content">
          {children
            .filter((child) => child)
            .map((child, index) =>
              React.Children.map(child, (pChild) => (
                <div
                  className={`wizard-content-item-wrapper ${this.getCSSAnimationClass(
                    index
                  )}`}
                >
                  <div className="wizard-content-item">{pChild}</div>
                </div>
              ))
            )}
        </div>
        <div className="wizard-buttons">
          <div className="wizard-button previous">
            <Button
              className="ci-button"
              onClick={this.previousStep}
              disabled={step === 0}
            >
              {BUTTON_TITLE_PREVOUS}
            </Button>
          </div>
          <div className="wizard-button abort">
            <Button className="ci-button" onClick={this.abort}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </div>
          <div className="wizard-button next">
            {lastStep ? (
              <Button
                className="ci-button"
                type="submit"
                form={formId}
                disabled={checkFormValid ? !checkFormValid() : false}
              >
                {BUTTON_TITLE_SUBMIT}
              </Button>
            ) : (
              <Button className="ci-button" onClick={this.nextStep}>
                {BUTTON_TITLE_NEXT}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
