import '../../styles/Complaint.scss';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  ComplaintContainerProps,
  ComplaintContainerState,
} from '../../@types/Complaint.d';
import { REQUEST_IDENTIFIER_LOAD_COMPLAINT_IFRAME } from '../../constants/constants';

/**
 * Container to hold the iFrame for the FPM Complaint module
 */
export default class ComplaintContainer extends React.Component<
  ComplaintContainerProps,
  ComplaintContainerState
> {
  iFrameRef = React.createRef<HTMLIFrameElement>();

  constructor(props: ComplaintContainerProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    const { enableLoadingOverlay } = this.props;
    const { current } = this.iFrameRef;

    if (current === null) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_COMPLAINT_IFRAME);

    current.onload = () =>
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_COMPLAINT_IFRAME);
  }

  render(): JSX.Element {
    const { iFrameURL } = this.props;

    return (
      <Row className="complaint-container">
        <Col className="complaint-iframe-container">
          {iFrameURL.length > 0 && (
            <iframe
              ref={this.iFrameRef}
              id="complaint"
              title="fpm-complaint"
              className="complaint-iframe"
              frameBorder="0"
              allowFullScreen
              allow="fullscreen"
              src={iFrameURL}
            />
          )}
        </Col>
      </Row>
    );
  }
}
