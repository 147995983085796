const config = {
  clientLocationMode: process.env.REACT_APP_CLIENT_LOCATION_MODE === 'true',
  novadex: process.env.REACT_APP_NOVADEX === 'true',
  apiUrl: process.env.REACT_APP_API_URL,
  mapUrl: process.env.REACT_APP_MAP_URL,
  layoutUrl: process.env.REACT_APP_LAYOUT_URL,
  complaintUrl: process.env.REACT_APP_COMPLAINT_URL,
  printToConsole: process.env.REACT_APP_PRINT_TO_CONSOLE === 'true',
  defaultWeekpart: process.env.REACT_APP_DEFAULT_WEEKPART,
  showPrice: process.env.REACT_APP_SHOW_PRICE === 'true',
  distributionIconSmall:
    process.env.REACT_APP_DISTRIBUTION_ICON_SMALL ?? 'distributionSmall.png',
  layoutIconSmall: process.env.REACT_APP_LAYOUT_ICON_SMALL ?? 'layoutSmall.png',
};
export default config;
