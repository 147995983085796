// Contains all network related values used in the app

export const FRA_API_KEY =
  'fmf2LsjNm5uKpXpDsqJgcCDri5UKcrgEEi1DNRHJeIhPeEej3CzldrDHR0DEAJtd';

export const SERVICE_AREA_DATA = 'areaDataService/';
export const PATH_AREAS = `${SERVICE_AREA_DATA}areas`;

export const SERVICE_SECURITY = 'securityService/';
export const PATH_LOGIN = `${SERVICE_SECURITY}login`;
export const PATH_TOKEN_AUTH = `${SERVICE_SECURITY}auth/`;

export const PATH_CHANGE_PASSWORD = `${SERVICE_SECURITY}changePassword`;
export const PATH_USERCLIENTS = `${SERVICE_SECURITY}userclients`;

// export const SERVICE_OFFER = 'offerService/';
// export const PATH_OFFER_REQUEST = `${SERVICE_OFFER}offerRequest`;

export const SERVICE_ORDER = 'orderService/';
export const PATH_ORDER_REQUEST = `${SERVICE_ORDER}order`;

export const SERVICE_GEO_DATA = 'geoDataService/';
export const PATH_CLIENT_DATA = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}`;
export const PATH_CLIENT_LAYOUTS_GET = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/layouts`;
export const PATH_CLIENT_LOCATIONS = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}/client/${clientUUID}/locations`;

export const PARAM_WEEKPART = 'weekpart';
export const PARAM_CLIENT_ID = 'clientid';
export const PARAM_PARENTORIGIN = 'parentorigin';
export const PARAM_CLIENT_LOCATION_MODE = 'clientlocationmode';
export const PARAM_EMAIL = 'email';
export const PARAM_AREAKEYS = 'areakeys';
export const PARAM_DISTRIBUTION_WEEK = 'distributionweek';
export const PARAM_DISTRIBUTION_YEAR = 'distributionyear';
export const PARAM_INIT_TARGET = 'inittarget';
export const PARAM_TOKEN = 'auth';

export const SERVICE_CALCULATION = 'calculationService/';
export const PATH_CALCULATION_FPP = `${SERVICE_CALCULATION}calculationFpp`;
