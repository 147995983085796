import * as React from 'react';

import { BurgerIconProps } from '../../../@types/Common.d';

/**
 * BurgerIcon which transforms to an "X" on click
 *
 * @param props
 * @returns
 */
const BurgerIcon: React.FC<BurgerIconProps> = (props: BurgerIconProps) => {
  const { onClick, onKeyDown, close, dark } = props;

  return (
    <div
      className={`burger-icon-container ${dark ? 'dark' : ''}`}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div className={`bar one ${close ? 'change' : ''}`} />
      <div className={`bar two ${close ? 'change' : ''}`} />
      <div className={`bar three ${close ? 'change' : ''}`} />
    </div>
  );
};

export default BurgerIcon;
