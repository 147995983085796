import * as React from 'react';

import { Modal, Form } from 'react-bootstrap';

import moment, { Moment } from 'moment';

import StepWizard from '../../Common/StepWizard/StepWizard';
import Contact from './Contact';
import Summary from './Summary';
import Message from '../../Common/Message';
import PrintData from './PrintData';
import Send from './Send';
import AdditionalOptionsContainer from './AdditionalOptionsContainer';
import Action from './Action';

import { getAllClientLocationAreas } from '../../../util/areaUtil';
import { generateAdditionalOptionsStub } from '../../../util/offerOrderUtil';
import { getClientLayouts } from '../../../util/api';

import {
  OFFER_MODAL_TITLE,
  OFFER_MODAL_STEPS_TITLES,
  WARNING_NO_AREAS_SELECTED_CONTENT,
  WARNING_NO_AREAS_SELECTED_TITLE,
  REQUIRED_FIELD_HINT,
} from '../../../constants/labels';

import {
  BillingType,
  ClientLocation,
  Salutation,
  DistributionAppointment,
  MasterFile,
  ExtraCopy,
  AdditionalOptions,
  WarningMessageType,
  KaufDaItem,
  KaufDaDurationPrice,
  ClientLayout,
  LayoutSelection,
} from '../../../@types/Data.d';
import { OrderModalProps, OrderModalState } from '../../../@types/OrderModal.d';

/**
 * A modal that enables the user to place an order for a selected client
 */
export default class OrderModal extends React.Component<
  OrderModalProps,
  OrderModalState
> {
  formRef = React.createRef<HTMLFormElement>();

  messageRef = React.createRef<Message>();

  actionRef = React.createRef<Action>();

  addtionalOptionsRef = React.createRef<AdditionalOptionsContainer>();

  contactRef = React.createRef<Contact>();

  printDataRef = React.createRef<PrintData>();

  summaryRef = React.createRef<Summary>();

  sendRef = React.createRef<Send>();

  constructor(props: OrderModalProps) {
    super(props);

    this.state = this.resetModal();

    this.checkFormValid = this.checkFormValid.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.setFormValidated = this.setFormValidated.bind(this);

    this.changeKaufDaItem = this.changeKaufDaItem.bind(this);
    this.changeActionName = this.changeActionName.bind(this);
    this.changeBillingClientLocation = this.changeBillingClientLocation.bind(
      this
    );
    this.changeBillingType = this.changeBillingType.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
    this.changeDistirbutionAppointment = this.changeDistirbutionAppointment.bind(
      this
    );
    this.changeDistributionDate = this.changeDistributionDate.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeLastname = this.changeLastname.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changePrename = this.changePrename.bind(this);
    this.changeSalutation = this.changeSalutation.bind(this);
    this.changePrintPerClientLocation = this.changePrintPerClientLocation.bind(
      this
    );
    this.changeExtraCopiesPerClientLocation = this.changeExtraCopiesPerClientLocation.bind(
      this
    );
    this.changeMasterFiles = this.changeMasterFiles.bind(this);
    this.changeExtraCopies = this.changeExtraCopies.bind(this);

    this.changeMessage = this.changeMessage.bind(this);
    this.changePrivacyCheck = this.changePrivacyCheck.bind(this);

    this.isDistributionDayBlocked = this.isDistributionDayBlocked.bind(this);
    this.isDistributionDayHighlighted = this.isDistributionDayHighlighted.bind(
      this
    );
    this.onDistributionDatePickerFocusChange = this.onDistributionDatePickerFocusChange.bind(
      this
    );
    this.onSubmit = this.onSubmit.bind(this);
    this.showMessage = this.showMessage.bind(this);

    const { current } = this.actionRef;
    if (current !== null) current.checkFormValid();
  }

  /**
   * Hides and resets the modal
   */
  onHide(): void {
    const { showModal } = this.props;

    this.setState(this.resetModal());

    showModal(false);
  }

  /**
   * Initializes the modal on show
   */
  onShow(): void {
    this.setState(this.resetModal(), () => {
      const { selectedClientLocations, areas, getPrice } = this.props;
      const { printPerClientLocation, additionalOptions } = this.state;

      if (
        getAllClientLocationAreas(selectedClientLocations).length <= 0 &&
        (areas?.length ?? 0) <= 0
      ) {
        this.showMessage(
          true,
          WARNING_NO_AREAS_SELECTED_CONTENT,
          WARNING_NO_AREAS_SELECTED_TITLE,
          'warn'
        );
      }

      getPrice(printPerClientLocation, additionalOptions);

      this.getClientLayouts();
    });
  }

  /**
   * Manages hide/show/focus of the distirbution datepucker
   *
   * @param distributionDatePickerFocused
   */
  onDistributionDatePickerFocusChange(
    distributionDatePickerFocused: boolean | null
  ): void {
    this.setState({ distributionDatePickerFocused });
  }

  /**
   * Sends a request to place an oder
   *
   * @param event
   */
  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();

    const formValid = this.checkFormValid();

    this.setState({ formValidated: true }, () => {
      // Only continue if all necessary information
      if (formValid) {
        const { submitOrder } = this.props;
        const {
          actionName,
          billingClientLocation,
          company,
          distributionDate,
          email,
          extraCopies,
          lastname,
          masterFiles,
          message,
          billingType,
          phone,
          prename,
          salutation,
          dateType,
          printPerClientLocation,
          additionalOptions,
        } = this.state;

        const { kaufDaItems } = additionalOptions;

        // Conevert the additional options in an api friendly fomrat
        const pAdditonalOptions = {
          kaufDaItems: kaufDaItems?.filter((item) => item.selected) ?? [],
        } as AdditionalOptions;

        submitOrder(
          actionName,
          distributionDate,
          email,
          extraCopies,
          lastname,
          masterFiles,
          billingType,
          phone,
          prename,
          pAdditonalOptions,
          dateType,
          printPerClientLocation,
          billingClientLocation,
          company,
          message,
          salutation
        );

        this.onHide();
      }
    });
  }

  /**
   * Sets the form as validated (shows missing information)
   *
   * @param validated
   */
  setFormValidated(validated: boolean = true): void {
    this.setState({ formValidated: validated });
  }

  /**
   * Get all the clients layouts from the layoutdesigner which
   * have been approved. Those can later be selected instead
   * of a print ready master file
   *
   * @returns
   */
  async getClientLayouts(): Promise<void> {
    const { client } = this.props;

    if (!client) return;

    const clientLayouts = (await getClientLayouts(
      client,
      'APPROVED'
    )) as ClientLayout[];

    if (!clientLayouts[0]?.stateFileId) return;

    this.setState({ clientLayouts });
  }

  /**
   * Manages wheather a day is highlighted or not in the
   * distribution day date selector
   *
   * @param day
   * @returns
   */
  isDistributionDayHighlighted(day: Moment): boolean {
    const { weekpart } = this.props;
    const { distributionDate } = this.state;

    if (distributionDate === null) return false;

    if (weekpart === 'BEST')
      return (
        distributionDate.isoWeek() === day.isoWeek() &&
        distributionDate.year() === day.year()
      );

    return distributionDate.unix() === day.unix();
  }

  /**
   * Manages wheather a day is blocked or not in the
   * distribution day date selector
   *
   * @param day
   * @returns
   */
  isDistributionDayBlocked(day: Moment): boolean {
    const { weekpart } = this.props;

    if (weekpart === 'BEST') {
      const tenDaysAhead = moment().add(10, 'd');
      return (
        day.isBefore(tenDaysAhead) || day.isoWeek() === tenDaysAhead.isoWeek()
      );
    }

    return (
      day.isBefore(moment().add(9, 'd')) ||
      (day.isoWeekday() !== 3 && weekpart === 'MIDWEEK') ||
      (day.isoWeekday() !== 6 && weekpart === 'WEEKEND')
    );
  }

  /**
   * Changes the billing type (client location, addres, etc.)
   *
   * @param billingType
   */
  changeBillingType(billingType: BillingType): void {
    const { client } = this.props;

    this.setState({
      billingType,
      billingClientLocation:
        billingType === 'PER_LOCATION'
          ? undefined
          : client?.clientLocations?.find(
              (clientLocation) => clientLocation.billingDefault
            ),
    });
  }

  /**
   * Changes the billing client location
   *
   * @param billingClientLocation
   */
  changeBillingClientLocation(billingClientLocation: ClientLocation): void {
    this.setState({ billingClientLocation });
  }

  /**
   * Changes the salutation
   *
   * @param salutation
   */
  changeSalutation(salutation: Salutation): void {
    this.setState({ salutation });
  }

  /**
   * Changes the prename
   *
   * @param prename
   */
  changePrename(prename: string): void {
    this.setState({ prename });
  }

  /**
   * Changes the lastname
   *
   * @param lastname
   */
  changeLastname(lastname: string): void {
    this.setState({ lastname });
  }

  /**
   * Changes the phone number
   *
   * @param phone
   */
  changePhone(phone: string): void {
    this.setState({ phone });
  }

  /**
   * Changes the email address
   *
   * @param email
   */
  changeEmail(email: string): void {
    this.setState({ email });
  }

  /**
   * Changes the action name
   *
   * @param actionName
   */
  changeActionName(actionName: string): void {
    this.setState({ actionName });
  }

  /**
   * Changes the company name
   *
   * @param company
   */
  changeCompany(company: string): void {
    this.setState({ company });
  }

  /**
   * Changes the distribution date
   *
   * @param distributionDate
   */
  changeDistributionDate(distributionDate: Moment | null): void {
    this.setState({ distributionDate });
  }

  /**
   * Changes the distribution appointment
   *
   * @param distributionAppointment
   */
  changeDistirbutionAppointment(
    distributionAppointment: DistributionAppointment
  ): void {
    this.setState({
      distributionAppointment,
      actionName: distributionAppointment.name,
      distributionDate: distributionAppointment.date,
      dateType: distributionAppointment.type,
    });
  }

  /**
   * Changes the print per client location attribute
   *
   * @param printPerClientLocation
   */
  changePrintPerClientLocation(printPerClientLocation: boolean): void {
    this.setState({ printPerClientLocation, masterFiles: [] }, () => {
      const { getPrice } = this.props;
      const { additionalOptions } = this.state;

      getPrice(printPerClientLocation, additionalOptions);
    });
  }

  /**
   * Changes the extra copies per client location
   *
   * @param extraCopiesPerClientLocation
   */
  changeExtraCopiesPerClientLocation(
    extraCopiesPerClientLocation: boolean
  ): void {
    this.setState({ extraCopiesPerClientLocation, extraCopies: [] });
  }

  /**
   * Changes the print master files
   *
   * @param masterFile
   */
  changeMasterFiles(masterFile: MasterFile | LayoutSelection): void {
    const { masterFiles } = this.state;
    let nMasterFiles = masterFiles;

    const index = masterFiles.findIndex(
      (pMasterFile) =>
        pMasterFile.clientLocationId === masterFile.clientLocationId
    );

    if (index < 0) nMasterFiles = [...masterFiles, ...[masterFile]];
    else nMasterFiles[index] = masterFile;

    this.setState({ masterFiles: nMasterFiles });
  }

  /**
   * Changes the number of extra copies
   *
   * @param extraCopy
   */
  changeExtraCopies(extraCopy: ExtraCopy): void {
    const { extraCopies } = this.state;
    const { selectedClientLocations } = this.props;
    let nExtraCopies = extraCopies;

    const index = extraCopies.findIndex(
      (pExtraCopy) => pExtraCopy.clientLocationId === extraCopy.clientLocationId
    );

    if (extraCopy.clientLocationId === -1) {
      nExtraCopies =
        selectedClientLocations && selectedClientLocations.length > 0
          ? selectedClientLocations.map(
              (clientLocation) =>
                ({
                  clientLocationId: clientLocation.id,
                  extraCopies: extraCopy.extraCopies,
                } as ExtraCopy)
            )
          : [
              {
                clientLocationId: -1,
                extraCopies: extraCopy.extraCopies,
              } as ExtraCopy,
            ];
    } else if (index < 0) nExtraCopies = [...extraCopies, ...[extraCopy]];
    else nExtraCopies[index] = extraCopy;

    this.setState({ extraCopies: nExtraCopies });
  }

  /**
   * Changes the message
   *
   * @param message
   */
  changeMessage(message: string): void {
    this.setState({ message });
  }

  /**
   * Changes the privacy check
   *
   * @param privacyCheck
   */
  changePrivacyCheck(privacyCheck: boolean): void {
    this.setState({ privacyCheck });
  }

  /**
   * Changes the kauf da additional option
   *
   * @param property
   * @param value
   * @param clientLocationId
   * @returns
   */
  changeKaufDaItem(
    property: keyof KaufDaItem,
    value: number | string | boolean | KaufDaDurationPrice,
    clientLocationId: number
  ): void {
    const { additionalOptions } = this.state;
    const { kaufDaItems } = additionalOptions;

    if (!kaufDaItems) return;

    const item = kaufDaItems.find(
      (pItem) => pItem.clientLocationId === clientLocationId
    );

    if (!item) return;

    item[property] = value as never;

    this.setState({ additionalOptions }, () => {
      const { getPrice } = this.props;
      const { printPerClientLocation } = this.state;

      getPrice(printPerClientLocation, additionalOptions);
    });
  }

  /**
   * Resets the modal to its original state
   *
   * @returns
   */
  resetModal(): OrderModalState {
    const { client, user, selectedClientLocations } = this.props;
    return {
      distributionDatePickerFocused: false,
      isOffer: client?.transmissionType !== 'ORDER',
      formValidated: false,
      billingType: client?.billingType ?? 'PER_LOCATION',
      billingClientLocation:
        (client?.billingType ?? 'PER_LOCATION') === 'LOCATION'
          ? client?.clientLocations?.find(
              (clientLocation) => clientLocation.billingDefault
            )
          : undefined,
      salutation: user?.salutation,
      prename: user?.prename ?? '',
      lastname: user?.lastname ?? '',
      phone: '',
      email: user?.email ?? '',
      actionName: '',
      distributionDate: null,
      distributionAppointment: undefined,
      dateType: 'DAY',
      company: '',
      printPerClientLocation: false,
      extraCopiesPerClientLocation: false,
      masterFiles: [],
      extraCopies: [],
      message: '',
      privacyCheck: false,
      showMessage: false,
      additionalOptions: generateAdditionalOptionsStub(
        selectedClientLocations ?? [],
        client?.additionalOptions
      ),
      clientLayouts: [],
    };
  }

  /**
   * Check if the form is valid
   *
   * @returns
   */
  checkFormValid(): boolean {
    const { current } = this.formRef;
    const { selectedClientLocations, areas } = this.props;

    if (current !== null)
      return (
        current.checkValidity() &&
        ((areas?.length ?? 0) > 0 ||
          getAllClientLocationAreas(selectedClientLocations).length > 0)
      );

    return false;
  }

  /**
  
   *
   * @param show
   * @param content
   * @param title
   * @param type
   */
  showMessage(
    show: boolean,
    content?: string,
    title?: string,
    type?: WarningMessageType
  ): void {
    const { current } = this.messageRef;

    if (current !== null) {
      current.setContents(content, title, type);
      this.setState({ showMessage: show });
    }
  }

  render(): JSX.Element {
    const {
      show,
      client,
      selectedClientLocations,
      areas,
      weekpart,
      user,
      product,
      price,
    } = this.props;
    const {
      isOffer,
      formValidated,
      distributionDatePickerFocused,
      billingType,
      salutation,
      prename,
      lastname,
      phone,
      email,
      actionName,
      distributionDate,
      billingClientLocation,
      distributionAppointment,
      company,
      printPerClientLocation,
      extraCopiesPerClientLocation,
      masterFiles,
      extraCopies,
      message,
      privacyCheck,
      showMessage,
      additionalOptions,
      clientLayouts,
    } = this.state;

    return (
      <Modal
        className="order-modal"
        show={show}
        onHide={this.onHide}
        onShow={this.onShow}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{OFFER_MODAL_TITLE(isOffer)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="order-modal-body">
          <Message
            ref={this.messageRef}
            show={showMessage}
            closeMessage={this.showMessage}
          />
          <Form
            ref={this.formRef}
            id="order-modal-from"
            className="order-moda-form"
            validated={formValidated}
            onSubmit={this.onSubmit}
            noValidate
          >
            <StepWizard
              titles={OFFER_MODAL_STEPS_TITLES}
              childrenRef={[
                this.actionRef,
                this.addtionalOptionsRef,
                this.contactRef,
                this.printDataRef,
                this.summaryRef,
                this.sendRef,
              ]}
              abort={this.onHide}
              checkFormValid={this.checkFormValid}
              setFormValidated={this.setFormValidated}
              formId="order-modal-from"
              showTitleBar
            >
              <Action
                ref={this.actionRef}
                distributionDateType={client?.distributionDateType ?? 'FREE'}
                distributionAppointment={distributionAppointment}
                isOffer={isOffer}
                weekpart={weekpart}
                datePickerFocused={distributionDatePickerFocused}
                actionName={actionName}
                distributionDate={distributionDate}
                distributionAppointments={
                  client?.distributionAppointments ?? []
                }
                changeActionName={this.changeActionName}
                changeDistributionDate={this.changeDistributionDate}
                changeDistributionAppointment={
                  this.changeDistirbutionAppointment
                }
                onDatePickerFocusChange={
                  this.onDistributionDatePickerFocusChange
                }
                isDayHighlighted={this.isDistributionDayHighlighted}
                isDayBlocked={this.isDistributionDayBlocked}
              />
              {client?.modules?.find(
                (module) => module.type === 'ADDITIONALOPTIONS'
              )?.enabled &&
                client?.additionalOptions?.some((option) => option.enabled) && (
                  <AdditionalOptionsContainer
                    ref={this.addtionalOptionsRef}
                    additionalOptions={additionalOptions}
                    additionalOptionSettings={client.additionalOptions}
                    distributionDate={distributionDate}
                    changeKaufDaItem={this.changeKaufDaItem}
                  />
                )}
              <Contact
                ref={this.contactRef}
                company={company}
                billingType={billingType}
                user={user}
                client={client}
                clientLocations={client?.clientLocations ?? []}
                billingClientLocation={billingClientLocation}
                salutation={salutation}
                prename={prename}
                lastname={lastname}
                phone={phone}
                email={email}
                changeBillingType={this.changeBillingType}
                changeBillingSubisdiary={this.changeBillingClientLocation}
                changeCompany={this.changeCompany}
                changeSalutation={this.changeSalutation}
                changePrename={this.changePrename}
                changeLastname={this.changeLastname}
                changePhone={this.changePhone}
                changeEmail={this.changeEmail}
              />
              {product.printDocRequired && (
                <PrintData
                  ref={this.printDataRef}
                  client={client}
                  clientLayouts={clientLayouts}
                  printPerClientLocation={printPerClientLocation}
                  extraCopriesPerClientLocation={extraCopiesPerClientLocation}
                  masterFiles={masterFiles}
                  extraCopies={extraCopies}
                  selectedClientLocations={selectedClientLocations ?? []}
                  changePrintPerClientLocation={
                    this.changePrintPerClientLocation
                  }
                  changeExtraCopiesPerClientLocation={
                    this.changeExtraCopiesPerClientLocation
                  }
                  changeMasterFiles={this.changeMasterFiles}
                  changeExtraCopies={this.changeExtraCopies}
                />
              )}
              <Summary
                ref={this.summaryRef}
                client={client}
                user={user}
                areas={areas}
                selectedClientLocations={selectedClientLocations}
                prename={prename}
                lastname={lastname}
                salutation={salutation}
                email={email}
                phone={phone}
                billingClientLocation={billingClientLocation}
                extraCopies={extraCopies}
                billingType={billingType}
                product={product}
                price={price}
                additionalOptions={additionalOptions}
              />
              <Send
                ref={this.sendRef}
                message={message}
                privacyCheck={privacyCheck}
                changeMessage={this.changeMessage}
                changePrivacyCheck={this.changePrivacyCheck}
              />
            </StepWizard>
            <div className="required-hint-footer">
              <div>{REQUIRED_FIELD_HINT}</div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
