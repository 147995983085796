import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../../Common/CustomTooltip';

import { removeAllAreas } from '../../../util/iFrameMessageUtil';

import {
  TOOLTIP_CLIENT_LOCATION_COLLAPSE_ALL,
  TOOLTIP_CLIENT_LOCATION_EXPAND_ALL,
  TOOLTIP_CLIENT_LOCATION_REMOVE_ALL,
} from '../../../constants/labels';

import {
  AreaListHeaderProps,
  AreaListHeaderItemProps,
} from '../../../@types/AreaList.d';

/**
 * An item beloning to the header
 *
 * @param props
 * @returns
 */
const AreaListHeaderItem: React.FC<AreaListHeaderItemProps> = (
  props: AreaListHeaderItemProps
) => {
  const { icon, tooltipLabel, action, mrAuto, position } = props;

  /**
   * On click listener for the button
   *
   * @param event
   */
  const onClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    action();
  };

  return (
    <Col
      xs={2}
      md={1}
      className={`${mrAuto ? 'mr-auto' : ''} item ${position}`}
    >
      <CustomTooltip tooltipId={`icon-${tooltipLabel}`} content={tooltipLabel}>
        <FontAwesomeIcon className="hover-icon" onClick={onClick} icon={icon} />
      </CustomTooltip>
    </Col>
  );
};

/**
 * A header for the area list which provides functions to manages the whole list
 *
 * @param props
 * @returns
 */
const AreaListHeader: React.FC<AreaListHeaderProps> = (
  props: AreaListHeaderProps
) => {
  const { collapseAll, showCollapseAll, showAll, summary } = props;

  /**
   * Method to collapse all selected client lcoations
   */
  const collapseAllItems = (): void => {
    collapseAll();
  };

  /**
   * Method to remove all selected areas
   */
  const removeAllItems = (): void => {
    removeAllAreas();
  };

  return (
    <Row className="no-gutters area-list-header">
      {showCollapseAll && (
        <AreaListHeaderItem
          icon={showAll ? faAngleDoubleDown : faAngleDoubleUp}
          tooltipLabel={
            showAll
              ? TOOLTIP_CLIENT_LOCATION_COLLAPSE_ALL
              : TOOLTIP_CLIENT_LOCATION_EXPAND_ALL
          }
          action={collapseAllItems}
          mrAuto
          position="left"
        />
      )}
      {!summary && (
        <AreaListHeaderItem
          icon={faTrash}
          tooltipLabel={TOOLTIP_CLIENT_LOCATION_REMOVE_ALL}
          action={removeAllItems}
          position="right"
        />
      )}
    </Row>
  );
};

export default AreaListHeader;
