import { Area, ClientLocation, Price, Product, Weekpart } from './Data.d';

export enum FPMMessageType {
  FPM_MESSAGE_TYPE_INIT_AREAS = 'init_areas',
  FPM_MESSAGE_TYPE_REMOVE_AREA = 'remove_area',
  FPM_MESSAGE_TYPE_UPDATE_LOCALITIES = 'update_localities',
  FPM_MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL = 'update_localities_external',
  FPM_MESSAGE_TYPE_UPDATE_AREAS = 'update_areas',
  FPM_MESSAGE_TYPE_CHANGE_WEEKPART = 'change_weekpart',
  FPM_MESSAGE_TYPE_ADD_CLIENT_LOCATION = 'add_clientLocation',
  FPM_MESSAGE_TYPE_REMOVE_CLIENT_LOCATION = 'remove_clientLocation',
  FPM_MESSAGE_TYPE_ADD_ORDER = 'add_order',
  FPM_MESSAGE_TYPE_CHANGE_PRODUCT = 'change_product',
  FPM_MESSAGE_TYPE_UPDATE_PRICE = 'update_price',
  FPM_MESSAGE_TYPE_REMOVE_ALL_AREAS = 'remove_all_areas',
  FPM_MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION = 'hide_clientLocation_selection',
  FPM_MESSAGE_TYPE_MAP_INFO = 'map_info',
}

export enum LayoutMessageType {
  LAYOUT_MESSAGE_TYPE_NAVIGATE = 'navigate',
  LAYOUT_MESSAGE_FINISH_DESIGN = 'finish_design',
}

export interface LayoutMessage {
  payload: LayoutMessagePayload;
  type: LayoutMessageType;
}
export interface FPMMessage {
  payload: FPMMessagePayload;
  type: FPMMessageType;
}
export interface AreaMessagePayload {
  totalCirculation: number;
  areas: Area[] | ClientLocation[];
  weekpart: Weekpart;
  price?: Price;
}

export interface LocalityMessagePayload {
  totalCirculation?: number;
  weekpart: Weekpart;
  area?: Area;
  areas?: Area[];
}

export interface InitAreasPayload {
  weekpart: Weekpart;
  areas: Area[] | ClientLocation[];
}

export interface MapInfoPayload {
  totalCirculation: number;
  areas: Area[] | ClientLocation[];
  price: Price;
}

export interface HideClientLocationSelectionPayload {
  clientLocationId: number;
  show: boolean;
}

export type LayoutMessageNavType = '/' | '/layouts' | '/templates';
export type FinishActionNavType = '/' | '/layout' | '/distribution';

export type LayoutMessagePayload = LayoutMessageNavType;

export type FPMMessagePayload =
  | Area
  | Product
  | LocalityMessagePayload
  | ClientLocation
  | Weekpart
  | AreaMessagePayload
  | HideClientLocationSelectionPayload
  | MapInfoPayload;
