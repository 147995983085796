// Contains all constant values used in the app

// eslint-disable-next-line import/no-cycle
import { Module, Product } from '../@types/Data.d';

export const COOKIE_USER_NAME = 'user';

export const ROUTER_PATH_HOME = '/';
export const ROUTER_PATH_DISTRIBUTION = '/distribution';
export const ROUTER_PATH_COMPLAINT = '/complaint';
export const ROUTER_PATH_LAYOUT = '/layout';

export const TRANSMISSION_TYPE_ORDER = 'ORDER';
export const TRANSMISSION_TYPE_OFFER = 'OFFER';

export const WEEKPART_WEEKEND: string = 'WEEKEND';
export const WEEKPART_MIDWEEK: string = 'MIDWEEK';
export const WEEKPART_BEST: string = 'BEST';

export const SALUTATION_MALE = 'Herr';
export const SALUTATION_FEMALE = 'Frau';

export const SALUTATION_ARRAY = [
  { value: SALUTATION_MALE, label: SALUTATION_MALE },
  { value: SALUTATION_FEMALE, label: SALUTATION_FEMALE },
];

export const EXTRA_COPIES_ARRAY = [
  { value: 0, label: 'Keine' },
  { value: 100, label: '100 Stk.' },
  { value: 250, label: '250 Stk.' },
];

export const DEFAULT_PRODUCT_DISTRIBUTION = 'Verteilung';
export const DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION = 'Druck & Verteilung';

export const DEFAULT_PRODUCTS = [
  {
    name: DEFAULT_PRODUCT_DISTRIBUTION,
    printDocRequired: false,
    id: -1,
  } as Product,
  {
    name: DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION,
    printDocRequired: true,
    id: -2,
  } as Product,
];

export const REQUEST_IDENTIFIER_GET_CLIENTS = 'GET_CLIENTS';
export const REQUEST_IDENTIFIER_GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS = 'GET_CLIENT_LOCATIONS';
export const REQUEST_IDENTIFIER_CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REQUEST_IDENTIFIER_LOGIN = 'LOGIN';
export const REQUEST_IDENTIFIER_SEND_OFFER_ORDER = 'SEND_OFFER_ORDER';
export const REQUEST_IDENTIFIER_LOAD_LAYOUT_IFRAME = 'LOAD_LAYOUT_IFRAME';
export const REQUEST_IDENTIFIER_LOAD_COMPLAINT_IFRAME = 'LOAD_COMPLAINT_IFRAME';
