import { ClientLocation, Area, Locality } from '../@types/Data.d';

/**
 * Get an areas circulation calculated with
 * the selected localities circulations
 *
 * @param area
 * @returns
 */
export const getAreaCirculation = (area: Area): number =>
  area.localities.reduce((locAcc: number, locality: Locality) => {
    let rLocAcc = locAcc;

    if (locality.selected && !Number.isNaN(+locality.circulation as number)) {
      rLocAcc += locality.circulation;
    }
    return rLocAcc;
  }, 0);

/**
 * Get the total circulation of an array of areas
 *
 * @param areas
 * @returns
 */
export const getTotalCirculationAreas = (areas?: Area[]): number =>
  areas?.reduce((acc, area) => {
    let rAcc = acc;

    if (!Number.isNaN(+area.circulation)) {
      rAcc += getAreaCirculation(area);
    }

    rAcc += area.additionalAreas.reduce(
      (accc: number, additionalArea: Area) => {
        let rAccc = accc;
        rAccc += getAreaCirculation(additionalArea);
        return rAccc;
      },
      0
    );
    return rAcc;
  }, 0) ?? 0;

/**
 * Get the total circulation of an array of client
 * locations selected areas
 *
 * @param selectedClientLocations
 * @returns
 */
export const getTotalCirculationClientLocations = (
  selectedClientLocations?: ClientLocation[]
): number =>
  selectedClientLocations?.reduce(
    (acc: number, clientLocation: ClientLocation) => {
      let rAcc = acc;

      rAcc += getTotalCirculationAreas(clientLocation.areas);

      return rAcc;
    },
    0
  ) ?? 0;

/**
 * Get all areas selected by an array of
 * client locations
 *
 * @param clientLocations
 * @returns
 */
export const getAllClientLocationAreas = (
  clientLocations?: ClientLocation[]
): Area[] =>
  clientLocations?.reduce((acc, clientLocation) => {
    const rAcc = acc;

    return [...rAcc, ...clientLocation.areas];
  }, [] as Area[]) ?? ([] as Area[]);
