import * as React from 'react';

import { Form, Col } from 'react-bootstrap';

import ContentContainer from './ContentContainer';
import StepWizardStepAbstract from '../../Common/StepWizard/StepWizardStepAbstract';

import {
  OFFER_MODAL_SEND_MESSAGE_TITLE,
  OFFER_MODAL_SEND_MESSAGE_LABEL,
  OFFER_MODAL_SEND_PRIVACY_TITLE,
  OFFER_MODAL_SEND_PRIVACY_LABEL,
  OFFER_MODAL_SEND_PRIVACY_INFO,
  OFFER_MODAL_SEND_PRIVACY_INVALID,
} from '../../../constants/labels';

import { SendProps, SendState } from '../../../@types/OrderModal.d';

/**
 * Component which allows the user to leave a personal message
 * and accept the privacy agreement
 */
export default class Send extends StepWizardStepAbstract<SendProps, SendState> {
  constructor(props: SendProps) {
    super(props);

    this.state = { formValidated: false };

    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangePrivacyCheck = this.onChangePrivacyCheck.bind(this);
  }

  /**
   * On change listener for the message text area
   *
   * @param event
   */
  onChangeMessage(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeMessage } = this.props;

    changeMessage(event.currentTarget.value);
  }

  /**
   * On change listener for the privacy check
   *
   * @param event
   */
  onChangePrivacyCheck(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changePrivacyCheck } = this.props;

    changePrivacyCheck(event.currentTarget.checked);
  }

  render(): JSX.Element {
    const { formValidated } = this.state;
    const { message, privacyCheck } = this.props;

    return (
      <>
        <Form
          ref={this.formRef}
          id="send-form"
          validated={formValidated}
          noValidate
        >
          <ContentContainer title={OFFER_MODAL_SEND_MESSAGE_TITLE}>
            <Form.Row className="d-flex">
              <Col xl={2}>
                <Form.Label className="pr-4">
                  {OFFER_MODAL_SEND_MESSAGE_LABEL}
                </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  value={message}
                  onChange={this.onChangeMessage}
                  as="textarea"
                />
              </Col>
            </Form.Row>
          </ContentContainer>
          <ContentContainer title={OFFER_MODAL_SEND_PRIVACY_TITLE}>
            <div className="custom-switch-container">
              <Form.Check
                type="checkbox"
                id="privacy-check"
                custom
                label={OFFER_MODAL_SEND_PRIVACY_LABEL}
                onChange={this.onChangePrivacyCheck}
                checked={privacyCheck}
                isInvalid={!privacyCheck}
                feedback={OFFER_MODAL_SEND_PRIVACY_INVALID}
                required
              />
              <div className="custom-switch-info">
                <small>{OFFER_MODAL_SEND_PRIVACY_INFO}</small>
              </div>
            </div>
          </ContentContainer>
        </Form>
      </>
    );
  }
}
