import '../../../styles/AreaList.scss';

import * as React from 'react';

import { Col, Row, ListGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

import CirculationItem from '../../Common/CirculationItem';
import CustomTooltip from '../../Common/CustomTooltip';
import AreaList from './AreaList';

import { sortClientLocations } from '../../../util/sortUtil';
import { getTotalCirculationAreas } from '../../../util/areaUtil';
import {
  removeClientLocation,
  hideClientLocationSelection,
} from '../../../util/iFrameMessageUtil';

import {
  TOOLTIP_SELECTED_CLIENT_LOCATION_REMOVE,
  TOOLTIP_SELECTED_CLIENT_LOCATION_HIDE,
  PRICE_SUM_LABEL,
} from '../../../constants/labels';

import {
  ClientLocationAreaListProps,
  ClientLocationAreaListItemProps,
  ClientLocationAreaListItemState,
} from '../../../@types/AreaList.d';

/**
 * A list item representing a selected client location with holds a number of areas
 */
class ClientLocationAreaListItem extends React.Component<
  ClientLocationAreaListItemProps,
  ClientLocationAreaListItemState
> {
  constructor(props: ClientLocationAreaListItemProps) {
    super(props);

    const { show } = this.props;

    this.state = { show };

    this.onClickCollapse = this.onClickCollapse.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onChangeHideSelection = this.onChangeHideSelection.bind(this);
  }

  componentDidUpdate(prevProps: ClientLocationAreaListItemProps): void {
    const { show } = this.props;

    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.show !== show) this.setState({ show });
  }

  /**
   * On click listener for the collapse button
   *
   * @param event
   */
  onClickCollapse(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
    event.stopPropagation();
    const { show } = this.state;
    this.setState({ show: !show });
  }

  /**
   * On click listener for the remove button
   *
   * @param event
   */
  onClickRemove(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
    event.stopPropagation();
    const { clientLocation } = this.props;

    removeClientLocation(clientLocation);
  }

  /**
   * On click listener for the hide button
   *
   * @param event
   */
  onChangeHideSelection(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    const { clientLocation } = this.props;

    hideClientLocationSelection(clientLocation, event.currentTarget.checked);
  }

  render(): JSX.Element {
    const { clientLocation, weekpart, summary } = this.props;
    const { show } = this.state;

    return (
      <ListGroup.Item className="clientLocation-area-list-item">
        <Row className="clientLocation-area-list-row">
          <Col xs={1} sm={1} md={1} className="p-0 pl-2">
            <FontAwesomeIcon
              className="hover-icon"
              onClick={this.onClickCollapse}
              icon={show ? faChevronDown : faChevronUp}
            />
          </Col>
          <Col className={`p-0 ${summary ? 'pr-2' : ''}`}>
            <Row className="no-gutters">
              <Col className="custom-text-wrap text-bold p-0">
                {clientLocation.name}
              </Col>
              <Col xs={3} sm={4} md={4} className="p-0">
                <CirculationItem
                  weekpart={weekpart}
                  circulation={getTotalCirculationAreas(clientLocation.areas)}
                />
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="p-0 text-right">
                {PRICE_SUM_LABEL(
                  clientLocation.price?.total,
                  clientLocation.price?.totalPerThousand
                )}
              </Col>
            </Row>
          </Col>
          {!summary && (
            <Col xs={2} sm={2} md={2} className="p-0 pr-2">
              <Row className="no-gutters clientLocation-icons">
                <Col xs={6} className="text-right">
                  <CustomTooltip
                    tooltipId={`selected-clientLocation-${clientLocation.id}-hide`}
                    content={TOOLTIP_SELECTED_CLIENT_LOCATION_HIDE}
                  >
                    <Form.Check
                      id={`hide-selection-check-${clientLocation.id}`}
                      custom
                      type="checkbox"
                      onChange={this.onChangeHideSelection}
                      checked={clientLocation.show}
                      label=""
                    />
                  </CustomTooltip>
                </Col>
                <Col xs={6} className="text-right">
                  <CustomTooltip
                    tooltipId={`clientLocation-${clientLocation.id}-rem`}
                    content={TOOLTIP_SELECTED_CLIENT_LOCATION_REMOVE}
                  >
                    <FontAwesomeIcon
                      className="icon-remove hover-icon"
                      onClick={this.onClickRemove}
                      icon={faTrash}
                    />
                  </CustomTooltip>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {clientLocation.areas && clientLocation.areas.length > 0 && (
          <Row className="clientLocation-area-list-row">
            <AreaList
              show={show}
              summary={summary}
              weekpart={weekpart}
              areas={clientLocation.areas}
            />
          </Row>
        )}
      </ListGroup.Item>
    );
  }
}

/**
 * A list containing a number of ClientLocationAreaListItems
 *
 * @param props
 * @returns
 */
const ClientLocationAreaList: React.FC<ClientLocationAreaListProps> = (
  props: ClientLocationAreaListProps
) => {
  const { clientLocations, weekpart, showAll, summary } = props;

  return (
    <ListGroup className="h-100 selected-clientLocation-list">
      {sortClientLocations(clientLocations).map((clientLocation) => (
        <ClientLocationAreaListItem
          key={clientLocation.id}
          show={showAll}
          clientLocation={clientLocation}
          weekpart={weekpart}
          summary={summary}
        />
      ))}
    </ListGroup>
  );
};

export default ClientLocationAreaList;
