import * as React from 'react';
import {
  StepWizardStepProps,
  StepWizardStepState,
  StepWizardStep,
} from '../../../@types/StepWizard.d';

/**
 * Abstract class to enable step wizard callback functionallity
 * for React.Children of the StepWizard component
 */
export default class StepWizardStepAbstract<
    P extends StepWizardStepProps,
    S extends StepWizardStepState
  >
  extends React.Component<P, S>
  implements StepWizardStep {
  formRef = React.createRef<HTMLFormElement>();

  constructor(props: P) {
    super(props);

    this.state = {
      formValidated: false,
    } as Readonly<S>;

    this.checkFormValid = this.checkFormValid.bind(this);
  }

  /**
   * Check if the Components form is valid
   *
   * @returns
   */
  checkFormValid(): boolean {
    const { current } = this.formRef;

    if (current === null) return true;

    this.setState({
      formValidated: true,
    });

    return current.checkValidity();
  }
}
