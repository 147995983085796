import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import {
  BUTTON_TITLE_CONFIRM,
  BUTTON_TITLE_ABORT,
} from '../../constants/labels';

import {
  ConfirmationModalProps,
  ConfirmationModalState,
} from '../../@types/Modal.d';

/**
 * Modal to get confirmation from the user.
 * After confirmation the modal triggers a callback
 * which triggers the confirmed function
 */
export default class ConfirmationModal extends React.Component<
  ConfirmationModalProps,
  ConfirmationModalState
> {
  constructor(props: ConfirmationModalProps) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
  }

  /**
   * Hides the modal
   */
  onHide(): void {
    const { showModal } = this.props;

    showModal(false, undefined, undefined, undefined);
  }

  /**
   * Confirms and triggers the action
   *
   * @param event
   */
  onClickConfirm(event: React.MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { callback } = this.state;

    if (callback) callback();

    this.onHide();
  }

  /**
   * Set the contents of the modal
   *
   * @param title
   * @param content
   * @param callback
   */
  setModalContents(title: string, content: string, callback: Function): void {
    this.setState({ title, content, callback });
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { title, content } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onClickConfirm}
            >
              {BUTTON_TITLE_CONFIRM}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onHide}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
