import '../../../styles/MainMenu.scss';

import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faHome,
  faPlusSquare,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import { navigateLayout } from '../../../util/iFrameMessageUtil';
import {
  HEADER_USER_MENU_OPTION_CHANGE_PASS,
  HEADER_USER_MENU_OPTION_LOGOUT,
  MAIN_MENU_ITEM_LABEL_DISTRIBUTION,
  MAIN_MENU_ITEM_LABEL_HOME,
  MAIN_MENU_ITEM_LABEL_LAYOUT,
  MAIN_MENU_ITEM_LABEL_LAYOUT_NEW,
  MAIN_MENU_ITEM_LABEL_LAYOUT_LOAD,
  MAIN_MENU_ITEM_LABEL_COMPLAINT,
} from '../../../constants/labels';
import {
  ROUTER_PATH_COMPLAINT,
  ROUTER_PATH_DISTRIBUTION,
  ROUTER_PATH_HOME,
  ROUTER_PATH_LAYOUT,
} from '../../../constants/constants';

import { LayoutMessageNavType } from '../../../@types/MessageTypes.d';
import {
  MainMenuProps,
  MainMenuContentItemProps,
} from '../../../@types/MainMenu.d';

import config from '../../../config';

/**
 * A menu item
 *
 * @param props
 * @returns
 */
const MainMenuContentItem: React.FC<MainMenuContentItemProps> = (
  props: MainMenuContentItemProps
) => {
  const { title, icon, action, secondLevel } = props;

  /**
   * On click listener for the menu item
   *
   * @param event
   */
  const onClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    action();
  };

  /**
   * On key down listener for the menu item
   *
   * @param event
   */
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    action();
  };

  /**
   * A method to load a icon for the menu item
   *
   * @param iconId
   * @returns
   */
  const loadIcon = (iconId?: string): string =>
    // eslint-disable-next-line import/no-dynamic-require
    require(`../../../resources/img/icon/${iconId}`).default as string;

  return (
    <div
      className={`item  ${secondLevel ? 'second-level' : ''}`}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div className="icon">
        {typeof icon === 'string' ? (
          <img src={loadIcon(icon)} alt={title} />
        ) : (
          <div className="fa-icon">
            {icon && <FontAwesomeIcon icon={icon as IconDefinition} />}
          </div>
        )}
      </div>
      <div className="title">{title}</div>
    </div>
  );
};

/**
 * A slide-in menu for navigation
 *
 * @param props
 * @returns
 */
const MainMenu: React.FC<MainMenuProps> = (props: MainMenuProps) => {
  const {
    show,
    location,
    history,
    modules,
    showMenu,
    logoutUser,
    showChangePassword,
    changeLayoutIFrameURL,
  } = props;

  /**
   * Navigate to a section
   *
   * @param target
   * @param iFrameTarget
   */
  const navigateLayoutAction = (
    target: string,
    iFrameTarget?: LayoutMessageNavType
  ): void => {
    switch (target) {
      case ROUTER_PATH_LAYOUT:
        // Navigate within the layout iframe
        if (location.pathname === ROUTER_PATH_LAYOUT) {
          navigateLayout(iFrameTarget);
        } else {
          // Navigate within FPP
          changeLayoutIFrameURL(iFrameTarget);
          history.push(ROUTER_PATH_LAYOUT);
        }
        break;
      case ROUTER_PATH_DISTRIBUTION:
      case ROUTER_PATH_HOME:
      case ROUTER_PATH_COMPLAINT:
        // Navigate within FPP
        history.push(target);
        break;
      default:
    }

    showMenu(false);
  };

  /**
   * On click listener for change password menu item
   *
   * @param event
   */
  const onClickChangePass = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    // Launch the change password modal
    showChangePassword(true);
    showMenu(false);
  };

  /**
   * On click listener for change password menu item
   *
   * @param event
   * @returns
   */
  const onKeyDownChangePass = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    // Launch the change password modal
    showChangePassword(true);
    showMenu(false);
  };

  /**
   * On click listner for the logout menu item
   *
   * @param event
   */
  const onClickLogout = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    // Logout the user
    logoutUser();
    showMenu(false);
  };

  /**
   * On key down listner for the logout menu item
   *
   * @param event
   * @returns
   */
  const onKeyDownLogout = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    // Logout the user
    logoutUser();
    showMenu(false);
  };

  return (
    <div className={`main-menu-container ${show ? 'shown' : 'hidden'}`}>
      <div className="main-menu-header">
        <div className="main-menu-header-user-info" />
        <div className="main-menu-user-actions">
          <div
            className="item"
            role="button"
            tabIndex={-1}
            onClick={onClickChangePass}
            onKeyDown={onKeyDownChangePass}
          >
            {HEADER_USER_MENU_OPTION_CHANGE_PASS}
          </div>
          <div
            className="item"
            role="button"
            tabIndex={-1}
            onClick={onClickLogout}
            onKeyDown={onKeyDownLogout}
          >
            {HEADER_USER_MENU_OPTION_LOGOUT}
          </div>
        </div>
        <div className="separator" />
      </div>
      <div className="main-menu-body">
        <MainMenuContentItem
          title={MAIN_MENU_ITEM_LABEL_HOME}
          icon={faHome}
          action={() => navigateLayoutAction(ROUTER_PATH_HOME)}
        />
        {modules?.find((module) => module.type === 'LAYOUTDESIGN')?.enabled && (
          <>
            {' '}
            <MainMenuContentItem
              title={MAIN_MENU_ITEM_LABEL_LAYOUT}
              icon={config.layoutIconSmall}
              action={() => navigateLayoutAction(ROUTER_PATH_LAYOUT, '/')}
            />
            <MainMenuContentItem
              title={MAIN_MENU_ITEM_LABEL_LAYOUT_NEW}
              icon={faPlusSquare}
              action={() =>
                navigateLayoutAction(ROUTER_PATH_LAYOUT, '/templates')
              }
              secondLevel
            />
            <MainMenuContentItem
              title={MAIN_MENU_ITEM_LABEL_LAYOUT_LOAD}
              icon={faExternalLinkAlt}
              action={() =>
                navigateLayoutAction(ROUTER_PATH_LAYOUT, '/layouts')
              }
              secondLevel
            />{' '}
          </>
        )}

        {modules?.find((module) => module.type === 'LAYOUTDESIGN')?.enabled && (
          <MainMenuContentItem
            title={MAIN_MENU_ITEM_LABEL_DISTRIBUTION}
            icon={config.distributionIconSmall}
            action={() => navigateLayoutAction(ROUTER_PATH_DISTRIBUTION)}
          />
        )}
        {config.complaintUrl &&
          modules?.find((module) => module.type === 'COMPLAINT')?.enabled && (
            <MainMenuContentItem
              title={MAIN_MENU_ITEM_LABEL_COMPLAINT}
              icon={config.distributionIconSmall}
              action={() => navigateLayoutAction(ROUTER_PATH_COMPLAINT)}
            />
          )}
      </div>
      <div className="separator" />
      <div className="main-menu-footer" />
    </div>
  );
};

export default withRouter(MainMenu);
